import React, { useEffect } from 'react'
import { Title, Container, SubTitle, TextsContainer, ContainerBackground, SubmitButton } from './styles'
import background from 'assets/register/person-register-ok.svg'
import RegisterFooter from 'components/RegisterFooter'
import { useDispatch, useSelector } from 'react-redux'
import { actionVerifyAccount } from '../../redux/actions/auth/verifyAccount'
import { Button } from '@material-ui/core'
import Spinner from 'components/Spinner'

const VerifyAccount = ({ match, history }) => {
  const dispatch = useDispatch()
  const { isLoading, isSuccess, message } = useSelector(({ verifyAccount }) => verifyAccount)

  useEffect(() => {
    let token = match.params.token
    if (match.params) dispatch(actionVerifyAccount(token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params])

  if (isLoading) {
    return <Spinner showSpinner />
  }
  if (!isSuccess) {
    return (
      <Container>
        <ContainerBackground background={background}>
          <TextsContainer>
            <Title>Ups!</Title>
            <Title>{message}</Title>
            <Button onClick={() => history.push('/ingresar')} variant="contained" color="secondary">
              INGRESAR A MI CUENTA
            </Button>{' '}
          </TextsContainer>
          <RegisterFooter specialHeight={true} />
        </ContainerBackground>
      </Container>
    )
  }
  return (
    <Container>
      <ContainerBackground background={background}>
        {/* <Nav title="VALIDAR EMAIL" /> */}
        <TextsContainer>
          <Title>
            HAS VALIDADO TU CUENTA CORRECTAMENTE!
            <br />
            Ya puedes solicitar tu prestamo
          </Title>
          <SubTitle>
            <Button onClick={() => history.push('/ingresar')} variant="contained" color="secondary">
              INGRESAR A MI CUENTA
            </Button>{' '}
          </SubTitle>
        </TextsContainer>
        <RegisterFooter specialHeight={true} />
      </ContainerBackground>
    </Container>
  )
}

export default VerifyAccount
