import styled from 'styled-components'
import { Button, Grid, TextField, InputLabel } from '@material-ui/core'

export const Container = styled.div`
  text-align: center;
  color: #fff;
  padding: 16px;
  background: #29235c;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  h4 {
    font-size: 3rem;
    font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 1.5rem;
    font-family: inherit;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0;
    h4 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`
export const PicturesContainer = styled(Container)`
  background: #fff;
  width: 100%;
  margin: 0 auto;
  label {
    margin: 0 auto;
  }
`

export const ValidateNumeroTramite = styled(Button)`
  height: 35px;
  background: #1ec0ca;
  color: #fff;
  width: 20%;
  margin: 0 auto;
`
export const FullSizeGrid = styled(Grid)`
  padding: 12px;
`
export const PaperBackground = styled.div`
cursor:pointer;
background: url('${({ background }) => background}');
background-repeat: no-repeat;
    background-position-x: center;
    background-position-y:  ${({ size }) => (size ? '0' : 'center')};;
    background-size: ${({ size }) => (size ? 'cover' : '100%')};
    width: 70%;
    height: 230px;
    margin: 0 auto;
}
h4, p {
  color: transparent;
}
@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
  min-height: 240px;
    height: 100%;
    background-size: ${({ size }) => (size ? '40%' : '60%')};
}
`
export const SuccesImg = styled.div`
  background: url('${({ background }) => background}');
`

export const CustomTextField = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
  .MuiInput-input {
    color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export const CustomInputLabel = styled(InputLabel)`
  margin: 20px 0 10px 0;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey['500']};
`
export const ProcessImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;

  & > div.spinner-img {
    margin: 0 auto;
  }
`