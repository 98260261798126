import axios from 'axios'
import { RESET_STATE, BASE_URL_API, SEARCH_LOGIN_START, SEARCH_LOGIN_COMPLETE, SEARCH_LOGIN_ERROR } from 'redux/actionTypes'

export const actionSignIn = (data) => async (dispatch) => {
  dispatch({ type: SEARCH_LOGIN_START })
  try {
    const response = await axios.post(BASE_URL_API + '/api/auth/login', data)
    const { success, token, user } = response.data
    if (success && token) {
      localStorage.setItem('jwt', token)
    }
    // esto está muy mal
    response.data === "SERVIDOR MONTADO CORRECTAMENTE" ?
      dispatch({ type: SEARCH_LOGIN_ERROR, msg: 'Hmm, algo anda mal, por favor intentalo nuevamente más tarde!' }) :
      dispatch({ type: SEARCH_LOGIN_COMPLETE, msg: response.data.message, user, success })

  } catch (error) {
    // si el usuario le pega a una url como /api/auth/login3333333 > devuelve 403 en lugar de 500
    // otras respuestas erroneas se devuelven con 200 y "SERVIDOR MONTADO CORRECTAMENTE"
    dispatch({ type: SEARCH_LOGIN_ERROR, msg: 'Usuario o contraseña invalido.' })
  }
}

export const verifyAuthByToken = () => async (dispatch) => {
  const token = localStorage.getItem('jwt')
  if (!token) dispatch({ type: SEARCH_LOGIN_ERROR, msg: '' })
  else {
    dispatch({ type: SEARCH_LOGIN_START })
    try {
      const response = await axios.get(BASE_URL_API + '/api/auth/verify-token', {
        headers: {
          Authorization: token,
        },
      })
      const { success, user } = response.data
      dispatch({ type: SEARCH_LOGIN_COMPLETE, user, success })
    } catch (error) {
      localStorage.removeItem('jwt')
      localStorage.removeItem('admin')
      dispatch({ type: SEARCH_LOGIN_ERROR, msg: 'La sesión a expirado...' })
    }
  }
}

export const resetSignInState = () => async dispatch => {
  dispatch({
    type: RESET_STATE
  })
}