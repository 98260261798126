import styled, { css } from 'styled-components'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Modal from 'components/Modal'

export const SummaryContainer = styled.div`
  border-radius: 20px;
  background: #e6e6e6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`
export const FullSizeGrid = styled(Grid)`
  padding: 12px;
  margin: 0 auto;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > button {
    border-radius: 20px;
    width: 45%;
  }
  & > button > span {
    font-size: 10px;
    letter-spacing: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
    & > button {
      width: 48%;
    }
  }
`
export const SummaryItem = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #000;

  ${(props) =>
    props.isDefaulterCustomer &&
    css`
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      & > p {
        color: red;
      }
    `}
  ${(props) =>
    props.isFinished &&
    css`
      & > div > span,
      p {
        color: yellow;
      }
    `}
`
export const SummaryItemText = styled(Typography)`
  color: #1a1a1a;
  font-weight: 600;
`
export const PrincipalBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-bottom: 10%;
    padding: 10px;
  }
`
export const SecondaryBox = styled(Box)`
  width: 50%;
  position: absolute;
  bottom: -20px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`
export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
  },
}))

export const ModalWrapper = styled(Modal)`
  .MuiDialog-paperWidthSm {
    max-width: 1200px !important;
  }
`
