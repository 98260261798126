import { AuthRoute, PrivateRoute } from 'routes'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/* Components */
import Home from 'pages/Home'
import HomeUser from 'pages/HomeUser'
import Login from 'pages/Login'
import ModifyPassword from 'pages/ModifyPassword'
import MyAccount from 'pages/MyAccount'
import NewPassword from 'pages/NewPassword'
import { NotificationWrapper } from 'components/Notification'
import SignUp from 'pages/SignUp'
import Spinner from 'components/Spinner'
import VerifyAccount from 'pages/VerifyAccount'
import VerifyEmail from 'pages/VerifyEmail'
import VerifyTermAndConditions from 'pages/VerifyTermAndConditions'
import ReturnUrl from 'pages/ReturnUrl'

import { getVarEconomic } from 'redux/actions/varEconomic/varEconomic'
import { useNotification } from 'hooks/useNotification'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'

const App = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, isLoading } = useSelector(({ auth }) => auth)
  const { closeNotification, notificationState } = useNotification()
  const { isLoading: isLoadingVarEconomic } = useSelector(({ varEconomic }) => varEconomic)
  const checkLocalStorageToken = () => Boolean(localStorage.getItem('jwt'))

  // useEffect(() => {
  //   if (isAuthenticated && notificationState?.open) {
  //     closeNotification()
  //   }
  // }, [closeNotification, isAuthenticated, notificationState])

  return (
    <>
      <Spinner showSpinner={isLoading || isLoadingVarEconomic} />
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Home}
            restricted={isAuthenticated || checkLocalStorageToken()}
            redirect="/home"
          />
          <PrivateRoute
            exact
            path="/ingresar"
            restricted={isAuthenticated || checkLocalStorageToken()}
            redirect="/home"
            component={Login}
          />
          <PrivateRoute
            exact
            path="/registrarse"
            restricted={isAuthenticated || checkLocalStorageToken()}
            redirect="/home"
            component={SignUp}
          />
          <PrivateRoute
            exact
            path="/recuperar-cuenta"
            restricted={isAuthenticated || checkLocalStorageToken()}
            redirect="/home"
            component={ModifyPassword}
          />
          <PrivateRoute exact path="/contraseña-nueva/:token" component={NewPassword} />
          <PrivateRoute path="/validacion-cuenta/:token" component={VerifyAccount} />
          <AuthRoute
            exact
            path="/verificacion"
            component={VerifyEmail}
            isAuthenticated={isAuthenticated || checkLocalStorageToken()}
            redirect="/ingresar"
          />
          <AuthRoute
            isAuthenticated={isAuthenticated || checkLocalStorageToken()}
            path="/home"
            redirect="/ingresar"
            component={HomeUser}
          />
          <AuthRoute
            isAuthenticated={isAuthenticated || checkLocalStorageToken()}
            path="/user"
            redirect="/ingresar"
            component={MyAccount}
          />
          <PrivateRoute exact path="/terminos-y-condiciones/:token" component={VerifyTermAndConditions} />

          <PrivateRoute 
          path="/returnUrlMobbex/"
          component={ReturnUrl}
          />
        </Switch>
      </Router>
      <NotificationWrapper />
    </>
  )
}

export default App
