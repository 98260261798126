import {
  BASE_URL_API,
  CREATE_LOAN_COMPLETE,
  CREATE_LOAN_ERROR,
  CREATE_LOAN_START,
  GET_LOAN_BY_USER_COMPLETE,
  GET_LOAN_BY_USER_ERROR,
  GET_LOAN_BY_USER_START,
  GET_SIMULATE_LOAN_COMPLETE,
  GET_SIMULATE_LOAN_ERROR,
  GET_SIMULATE_LOAN_START,
  RESET_STATE,
} from 'redux/actionTypes'

import axios from 'axios'

export const loanCreate = (user, amount, term, interest) => async (dispatch) => {
  const token = localStorage.getItem('jwt')
  const body = {
    borrower_user_id: user.id,
    amount,
    amount_of_fees: term,
    interest,
  }
  dispatch({ type: CREATE_LOAN_START })
  try {
    // eslint-disable-next-line no-unused-vars
    await axios.post(BASE_URL_API + '/api/loans', body, {
      headers: {
        Authorization: token,
      },
    })
    dispatch({ type: CREATE_LOAN_COMPLETE, payload: { message: 'Prestamo creado correctamente', status: 200 } })
  } catch (error) {
    dispatch({ type: CREATE_LOAN_ERROR, payload: error.response })
  }
}

export const getLoanByUser = (userId) => async (dispatch) => {
  let loansArray = []
  dispatch({ type: GET_LOAN_BY_USER_START })
  const token = localStorage.getItem('jwt')
  const config = {
    headers: {
      Authorization: token,
    },
  }
  try {
    const response = await axios.get(BASE_URL_API + `/api/loans/user/${userId}`, config)
    const { success, loans } = response.data

    const getFeesByLoan = (loanId) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.get(`${BASE_URL_API}/api/loans/${loanId}/fees`, config)
          resolve(data)
        } catch (err) {
          reject(err)
        }
      })
    }

    const resolveLoansComplete = async (loans) => {
      for (let index = 0; index < loans.length; index++) {
        try {
          const { fees } = await getFeesByLoan(loans[index].id)
          loansArray.push({ ...loans[index], fees })
        } catch (error) {
          loansArray.push({ ...loans, fees: [] })
        }
      }
      return loansArray
    }

    const LoansAndFees = await resolveLoansComplete(loans)

    dispatch({
      type: GET_LOAN_BY_USER_COMPLETE,
      success,
      payload: LoansAndFees,
    })
  } catch (error) {
    dispatch({
      type: GET_LOAN_BY_USER_ERROR,
      error,
    })
  }
}

export const getSimulateLoan = (amount, term) => async (dispatch) => {
  dispatch({ type: GET_SIMULATE_LOAN_START })
  try {
    const { data } = await axios.get(
      `${BASE_URL_API}/api/loans/fees/simulator?amount=${amount}&amount_of_fees=${term}&sec_lvl_name=A1&scoring=999&actionType=Simulate`
    )
    dispatch({ type: GET_SIMULATE_LOAN_COMPLETE, payload: data })
  } catch (error) {
    console.log(error.message)
    dispatch({ type: GET_SIMULATE_LOAN_ERROR, payload: 'sin info' })
  }
}

export const resetLoanState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  })
}
