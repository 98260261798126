import React from "react";
import cx from "classnames";
import { IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./styles";

const LoanSnackbar = ({
  className,
  message,
  onClose,
  variant,
}) => {
  const classes = useStyles();

  const args = [className];
  if (variant && classes[variant]) {
    args.push(classes[variant]);
  }
  return (
    <SnackbarContent
      className={cx(...args)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={
        <IconButton key="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
    />
  );
};

export default LoanSnackbar;
