import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import AccountStatus from './pages/AccountStatus'
import LoansInfo from './pages/LoansInfo'
import Profile from './pages/Profile'
import CbuAndAlias from './pages/CbuAndAlias'
import { FooterContainer, SupportButton, NavMyAccount, MyAccountContainer } from './styles'

const MyAccount = () => {
  return (
    <MyAccountContainer>
      <NavMyAccount title="USUARIO" isFixed={true} />
      <div style={{ display: 'flex', paddingTop: '60px' }}>
        <Switch>
          <Route exact path="/user" component={LoansInfo} />
          <Route exact path="/user/estado-de-cuenta" component={AccountStatus} />
          <Route exact path="/user/perfil" component={Profile} />
          <Route exact path="/user/cbu-info" component={CbuAndAlias} />
        </Switch>
      </div>
      {/* <FooterContainer disableGutters={true} maxWidth={false}>
        <SupportButton variant="contained" color="default">
          Soporte
        </SupportButton>
      </FooterContainer> */}
    </MyAccountContainer>
  )
}

export default MyAccount
