import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Typography, CircularProgress } from '@material-ui/core'
import {
  ValidateNumeroTramite,
  PaperBackground,
  Container,
  PicturesContainer,
  CustomTextField,
  CustomInputLabel,
  ProcessImageContainer,
} from './styles'
import dniBack from 'assets/img/profile2.png'
import dniFront from 'assets/img/profile.png'
import success from 'assets/register/person-register-ok.svg'
import { useDispatch } from 'react-redux'
import { putDniImages, resetDniState } from '../../redux/actions/dni/uploadDni'
import Spinner from 'components/Spinner'
import { useNotification } from 'hooks/useNotification'
import resizer from './resizer'

const MAX_FILE_SIZE = 10485760;
const VALID_IMAGE_FORMAT = ["image/png", "image/gif", "image/jpeg"];

const UploadDniPicture = () => {
  const picsAndNumberInitState = {
    bothUploeaded: false,
    quantity: 0,
    files: [],
    step: 1,
  }

  const [pictures, setPictures] = useState(picsAndNumberInitState)

  const { showNotification } = useNotification()
  const [tramitNumber, setTramitNumber] = useState('')
  const [processingBigImage, setProcessingBigImage] = useState(false);

  const dispatch = useDispatch()
  const dniStatus = useSelector((state) => state.dni)
  const accountState = useSelector((state) => state.auth)
  const {
    user: { id },
  } = accountState
  const { isSucces, isLoading, hasError, wereProperlySent, message } = dniStatus
  const [fakeImg, setFakeImg] = useState("")

  const handleBigImage = (image) => {
    setProcessingBigImage(true)
    const fn = async () => {
      try {
        const resizedImage = await resizer(image);
        setFakeImg(URL.createObjectURL(resizedImage))
        if (resizedImage.size > MAX_FILE_SIZE) {
          showNotification('El archivo es muy grande \b 10Mb Máximo por imagen', 'error')
          return;
        }
        setPictures({
          bothUploeaded: pictures.quantity > 0 ? true : false,
          quantity: pictures.quantity + 1,
          files: [...pictures.files, resizedImage],
          step: pictures.step + 1,
        })
        setProcessingBigImage(false)
      } catch (err) {
        setProcessingBigImage(false)
        setPictures(picsAndNumberInitState);
        showNotification('Ocurrió un error al intentar procesar su imagen \b intente nuevamente', 'error')
      }
    }
    fn()
  }

  const handleChangePicture = (event) => {

    const image = event.target.files[0];

    if (image?.size > MAX_FILE_SIZE) {
      handleBigImage(image);
    }

    if (!VALID_IMAGE_FORMAT.includes(image?.type)) {
      showNotification('Formato inválido \b Sólo archivos JPEG', 'error')
      return
    }
    setPictures({
      bothUploeaded: pictures.quantity > 0 ? true : false,
      quantity: pictures.quantity + 1,
      files: [...pictures.files, image],
      step: pictures.step + 1,
    })
    event.target.value = '';
  }

  useEffect(() => {
    if (wereProperlySent) {
      window.location.href = '/user/estado-de-cuenta'
    }
  }, [wereProperlySent])

  const picturesMsg = (step) => {
    switch (step) {
      case 1:
        return (
          <Typography variant="h6" align="center">
            {' '}
            Primero, subi una foto de tu dni (frente) haciendo click en la imágen
          </Typography>
        )
      case 2:
        return (
          <Typography variant="h6" align="center">
            {' '}
            Como segundo paso, subí una foto de la parte trasera
          </Typography>
        )
      case 3:
        return (
          <Typography variant="h6" align="center">
            Ahora podés ingresar tu numero de tramite.
          </Typography>
        )
      default:
        return <></>
    }
  }

  const handleTramitNumber = (e) => {
    e.preventDefault()
    const value = e.target.value.replace(/[^0-9]/g, '')
    setTramitNumber(value)
  }

  const superoMaximoDigitos = tramitNumber.length > 11
  const disabled = superoMaximoDigitos || pictures.quantity < 2 || tramitNumber.length < 11

  const handleSendPicturesAndTramitNumber = () => {
    if (disabled) return
    dispatch(putDniImages(id, pictures.files, tramitNumber))
  }

  useEffect(() => {
    if (isSucces) {
      showNotification(message, 'success')
      dispatch(resetDniState())
    }
  }, [dispatch, isSucces, message, showNotification])

  useEffect(() => {
    if (hasError) {
      showNotification(message, 'error')
      dispatch(resetDniState())
      setPictures(picsAndNumberInitState)
    }
  }, [dispatch, hasError, picsAndNumberInitState, message, showNotification])

  return (
    <>
      <Container align="center">
        <div square={false}>
          <h4>VALIDAR IDENTIDAD</h4>
        </div>
      </Container>
      {processingBigImage ? (
        <ProcessImageContainer>
          <Typography variant="h6" align="center">
            Procesando imagen, aguarde...
          </Typography>
          <CircularProgress className="spinner-img" />
        </ProcessImageContainer>
      ) : (
        <>
          {fakeImg && <img src={fakeImg} alt="asd" />}
          <div style={{ paddingTop: '15px' }}>{picturesMsg(pictures.step)}</div>
          {(pictures.step === 1 || pictures.step === 2) && (
            <PicturesContainer align="center">
              <label htmlFor="upload-button">
                {pictures.bothUploeaded ? (
                  <PaperBackground size={pictures.bothUploeaded} succesSize={pictures.bothUploeaded} background={success} />
                ) : (
                  <PaperBackground background={pictures.quantity < 1 ? dniFront : dniBack} />
                )}
              </label>
              <input
                disabled={pictures.bothUploeaded}
                type="file"
                id="upload-button"
                style={{ display: 'none' }}
                onChange={handleChangePicture}
                accept="image/png, image/gif, image/jpeg"
              />
            </PicturesContainer>
          )}
          {pictures.step === 3 && pictures.bothUploeaded && (
            <>
              <CustomInputLabel>Ingrese numero de tramite que figura en el DNI</CustomInputLabel>
              <CustomTextField
                inputProps={{
                  maxlength: 11,
                }}
                label="Numero"
                type="number"
                name="nTramite"
                onChange={handleTramitNumber}
              />
              <ValidateNumeroTramite
                disabled={disabled}
                variant="contained"
                onClick={handleSendPicturesAndTramitNumber}
                color="secondary"
              >
                {dniStatus.wereProperlySent ? 'Datos ya validados' : 'Validar datos'}
              </ValidateNumeroTramite>
              {superoMaximoDigitos && (
                <label style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                  El número de trámite tiene un máximo de 11 dígitos.
                </label>
              )}
              {hasError && !isSucces && (
                <h4 style={{ color: 'red' }}>*Error en el numero de tramite, los datos no son validos</h4>
              )}
            </>
          )}
        </>
      )}
      <Spinner showSpinner={isLoading} />
    </>
  )
}
export default UploadDniPicture
