import { RESET_STATE, PUT_DNI_IMAGES_START, PUT_DNI_IMAGES_COMPLETE_SUCCESS, PUT_DNI_IMAGES_ERROR } from 'redux/actionTypes'

const initialState = {
  isSucces: false,
  isLoading: false,
  hasError: false,
  message: '',
  wereProperlySent: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PUT_DNI_IMAGES_START:
      return { ...state, isLoading: true }
    case PUT_DNI_IMAGES_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSucces: action.payload.success,
        wereProperlySent: true,
        message: action.payload.message || 'Las fotografiías se han subido correctamente'
      }
    case PUT_DNI_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        isSucces: false,
        hasError: true,
        wereProperlySent: false,
        message: action.payload.message || 'Hubo un error al subir su información'
      }
    case RESET_STATE: return initialState;
    default:
      return state
  }
}
