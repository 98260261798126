import React, { useEffect } from 'react'
import { PictureContainer, GeneralContent } from './styles'
import ProfileInfo from './components/ProfileInfo'
import UploadDniPicture from 'components/UploadDniPicture'
import { useSelector, useDispatch } from 'react-redux'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'

const Profile = () => {
  const dispatch = useDispatch()
  const accountState = useSelector((state) => state.auth)
  const { user } = accountState
  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  return (
    <GeneralContent>
      {user && user.is_validated_dni ? (
        <ProfileInfo account={user} />
      ) : (
        <PictureContainer>
          <UploadDniPicture userId={user.id} />
        </PictureContainer>
      )}
    </GeneralContent>
  )
}

export default Profile
