import styled from 'styled-components'
import { Slider } from '@material-ui/core'

export const SliderRefs = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`
export const StyledSlider = styled(Slider)`
  & .MuiSlider-track {
  }

  & .MuiSlider-rail {
    opacity: 1;
    background-color: white;
  }
`
