import {
  Action,
  Ad,
  BackgroundPerson,
  Bond,
  Column,
  CustomTextField,
  Divider,
  FooterContainer,
  Form,
  MiddleContainer,
  MsgError,
  MsgSucces,
  SubmitButton,
} from './styles'
import { CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Nav from 'components/Nav'
import RegisterFooter from 'components/RegisterFooter'
import background from 'assets/register/Group-95.svg'
import { newPassword } from 'redux/actions/modifyPassword/newPassword'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'

const Login = ({ history, match }) => {
  const [passwordValue, setPasswordValue] = useState(',')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [passwordDiferent, setPasswordDiferent] = useState('')

  //store de redux
  const stateNewPassword = useSelector((state) => state.newPassword)
  const { isSuccess, isLoading, error } = stateNewPassword
  const dispatch = useDispatch()

  
  useEffect(() => {
    dispatch(verifyAuthByToken())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePassword = ({ target }) => {
    setPasswordDiferent('')
    setPasswordValue(target.value)
  }

  const handleChangeVerifyPassword = ({ target }) => {
    setPasswordDiferent('')
    setVerifyPassword(target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let token = match.params.token
    if (passwordValue !== verifyPassword) {
      return setPasswordDiferent('Las contraseñas no coinciden')
    } else {
      dispatch(newPassword(passwordValue, token))
    }
  }

  return (
    <>
      <Nav title="CAMBIO DE CONTRASEÑA" isFixed={true} isLogin={true} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid md={6}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Column>
              <CustomTextField
                label="Ingrese su nueva constraseña"
                name="password"
                onChange={(e) => handleChangePassword(e)}
                type="password"
                required
                disabled={(isSuccess && true) || false}
              />
              <CustomTextField
                label="Repita su nueva contraña"
                name="verifyPassword"
                onChange={(e) => handleChangeVerifyPassword(e)}
                type="password"
                required
                disabled={(isSuccess && true) || false}
              />
            </Column>
            <MsgError>{error || passwordDiferent}</MsgError>
            <MsgSucces>{isSuccess}</MsgSucces>
            {isLoading ? (
              <CircularProgress size={100} color="primary" />
            ) : (
              <SubmitButton type="submit" variant="contained" color="primary" disabled={(isSuccess && true) || false}>
                Guardar Nueva Contraseña
              </SubmitButton>
            )}
          </Form>
          <Divider />
        </Grid>
      </div>
      <MiddleContainer>
        <BackgroundPerson src={background}>
          <Ad variant="h5" align="center" gutterBottom>
            ¿Aun no tenes cuenta?
          </Ad>

          <div align="center">
            <Bond to="/registrarse">
              <Action size="large" variant="contained" color="secondary">
                CREAR CUENTA
              </Action>
            </Bond>
          </div>

          <FooterContainer>
            <RegisterFooter />
          </FooterContainer>
        </BackgroundPerson>
      </MiddleContainer>
    </>
  )
}

export default Login
