import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isHomeUser }) => isHomeUser && '#1EC0CA'};
`
export const Content = styled.div`
  margin-top: 83px;
  padding: 10px 35px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 70%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`
export const ContentButton = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
    padding: 10px;
    button {
      margin: 0;
    }
  }
`

export const ContentText = styled.div`
  text-align: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3,
  p {
    color: #fff;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: ${({ bondFromHeader }) => bondFromHeader && 'none'};
    width: 100%;
    h3 {
      font-size: 19px;
    }
    p {
      font-size: 13px;
    }
  }
`
export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
  },
}))
