import React from 'react'

// La idea es empezar a reutilizar el componente Icon, este será del tipo HOC.

export const Icon = ({ className, width = '100%', height = '100%', name, fill }) => {
  // cada svg tiene un viewBox como atributo. nosotros lo matchearemos según el name dentro del objeto
  // en el caso de necesitar más iconos, llenar el objeto con el path que necesiten.
  const ViewBox = {
    hamburger: '0 2 30 25',
    arrow_down: '0 0 451.847 451.847',
  }

  const getPath = (name, className, fill) => {
    switch (name) {
      //solo tenemos un caso.
      case 'hamburger':
        return (
          <path
            className={className}
            id="hamburger"
            fillRule="non"
            d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
          />
        )
      case 'arrow_down':
        return (
          <path
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
          />
        )
      case 'loan-logo-light':
        return (
          <>
            <defs>
              <radialGradient id="radial-gradient" cx="0.285" cy="0.857" r="0.401" gradientTransform="matrix(0.32, 0.955, -1.523, 0.434, 1.499, 0.212)" gradientUnits="objectBoundingBox">
                <stop offset="0.128" stopColor="#00184e" />
                <stop offset="0.301" stopColor="#00184e" stopOpacity="0.718" />
                <stop offset="0.585" stopColor="#00184e" stopOpacity="0.329" />
                <stop offset="0.828" stopColor="#00184e" stopOpacity="0.094" />
                <stop offset="1" stopColor="#00184e" stopOpacity="0.008" />
                <stop offset="1" stopColor="#00184e" stopOpacity="0" />
              </radialGradient>
            </defs>
            <g id="Group_204" data-name="Group 204" transform="translate(-1249.811 1344.587)">
              <g id="Group_203" data-name="Group 203" transform="translate(-140 -1410.875)">
                <g id="Group_199" data-name="Group 199" transform="translate(1657.764 104.578)">
                  <g id="Group_198" data-name="Group 198">
                    <path id="Path_264" data-name="Path 264" d="M1539.4,268.18a38.339,38.339,0,0,1-38.292-38.289V95.868a19.146,19.146,0,1,0-38.293,0V229.891a76.679,76.679,0,0,0,76.585,76.582,19.146,19.146,0,0,0,0-38.293Z" transform="translate(-1462.816 -76.72)" fill="#29235c" />
                    <path id="Path_265" data-name="Path 265" d="M1566.42,97.586A76.584,76.584,0,1,0,1643,174.172,76.585,76.585,0,0,0,1566.42,97.586Zm0,114.875a38.291,38.291,0,1,1,38.289-38.289A38.292,38.292,0,0,1,1566.42,212.461Z" transform="translate(-1390.669 -21)" fill="#29235c" />
                    <path id="Path_266" data-name="Path 266" d="M1659.412,97.586a76.585,76.585,0,0,0-76.582,76.586v57.437a19.146,19.146,0,0,0,38.292,0V174.172a38.291,38.291,0,1,1,76.583,0v57.437a19.146,19.146,0,0,0,38.292,0V174.172A76.585,76.585,0,0,0,1659.412,97.586Z" transform="translate(-1142.337 -21)" fill="#29235c" />
                    <path id="Path_267" data-name="Path 267" d="M1612.428,97.586a76.584,76.584,0,1,0,39.988,141.91h0a19.15,19.15,0,0,0,36.6-7.888V174.172A76.585,76.585,0,0,0,1612.428,97.586Zm0,114.875a38.291,38.291,0,1,1,38.293-38.289A38.292,38.292,0,0,1,1612.428,212.461Z" transform="translate(-1267.801 -21)" fill="#29235c" />
                  </g>
                </g>
                <g id="Group_201" data-name="Group 201" transform="translate(1389.811 66.288)">
                  <g id="Group_200" data-name="Group 200">
                    <path id="Path_268" data-name="Path 268" d="M1561.9,257.749l-52.71-.033,93.931-93.543a57.649,57.649,0,0,0,0-81.273,56.655,56.655,0,0,0-40.6-16.612A58.465,58.465,0,0,0,1520.75,83.8L1406.713,198.517a57.211,57.211,0,0,0-.287,80.909,56.432,56.432,0,0,0,19.636,12.784,56.817,56.817,0,0,0,52.134,80.414h83.7a57.437,57.437,0,1,0,0-114.875ZM1433.807,225.16l115-114.856a18.873,18.873,0,0,1,13.488-5.656c.026,0,.048,0,.073,0a18.892,18.892,0,0,1,19,18.866,19.133,19.133,0,0,1-5.626,13.65l-120.447,120.52-8.038-.007a18.879,18.879,0,0,1-13.541-5.538A19.078,19.078,0,0,1,1433.807,225.16ZM1561.9,334.331h-83.7l.224-.066a18.874,18.874,0,0,1-13.536-5.539,19.077,19.077,0,0,1,.1-26.981l5.729-5.707h91.19a19.146,19.146,0,1,1,0,38.293Z" transform="translate(-1389.812 -66.288)" fill="#1ec0ca" />
                  </g>
                  <path id="Path_269" data-name="Path 269" d="M1561.9,257.749l-52.71-.033-53.9-.029-8.038-.007a18.879,18.879,0,0,1-13.541-5.538,19.078,19.078,0,0,1,.1-26.981l115-114.856a18.873,18.873,0,0,1,13.488-5.656c.026,0,.048,0,.073,0a18.868,18.868,0,0,1,13.463,5.535h0a18.891,18.891,0,0,1,5.531,13.331l.077.073a18.882,18.882,0,0,0-5.589-13.422L1603.113,82.9a56.632,56.632,0,0,0-40.594-16.612A58.465,58.465,0,0,0,1520.75,83.8L1406.713,198.517a57.211,57.211,0,0,0-.287,80.909,56.432,56.432,0,0,0,19.636,12.784,57.066,57.066,0,0,0,20.962,3.828H1561.9a19.149,19.149,0,0,1,19.148,19.148h38.293A57.439,57.439,0,0,0,1561.9,257.749Z" transform="translate(-1389.812 -66.288)" fill="url(#radial-gradient)" />
                  <path id="Path_270" data-name="Path 270" d="M1539.213,322.244h-83.7l.224-.066a18.874,18.874,0,0,1-13.536-5.539,19.077,19.077,0,0,1,.1-26.981l5.729-5.707,38.48-38.322,93.931-93.543a57.649,57.649,0,0,0,0-81.273h0l-27.263,27.267-.022.018h0a18.891,18.891,0,0,1,5.531,13.331,19.133,19.133,0,0,1-5.626,13.65L1432.6,245.6l-17.4,17.412a57.68,57.68,0,0,0-11.823,17.111,56.817,56.817,0,0,0,52.134,80.414h83.7a57.439,57.439,0,0,0,57.441-57.437h-38.293A19.146,19.146,0,0,1,1539.213,322.244Z" transform="translate(-1367.126 -54.201)" fill="#1ec0ca" />
                </g>
              </g>
            </g>
          </>
        )
      default:
        return (
          <path
            className={className}
            id="hamburger"
            fillRule="non"
            d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
          />
        )
    }
  }
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={ViewBox[name]}

      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, className, fill)}
    </svg>
  )
}
export default Icon