import React from 'react'
import { Typography } from '@material-ui/core'
import { MoneyIcon, LoanCalculatorContainer } from './styles'

const MainContainerHeader = (props) => {
  return (
    <LoanCalculatorContainer>
      <MoneyIcon />
      <Typography paragraph={true} align="center" variant="h6" gutterBottom>
        CONSEGUÍ HOY MISMO EL PRÉSTAMO QUE NECESITÁS
      </Typography>
      <Typography align="center" variant="body1" gutterBottom>
        Sin filas, sin documentación, sólo con tu DNI.
      </Typography>
    </LoanCalculatorContainer>
  )
}

export default MainContainerHeader
