import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { BoxHeader, Container, LoansWrapper } from './styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'
import { getLoanByUser } from 'redux/actions/loan/actionLoan'

import LoanItem from './components/LoanItem'
import NoLoansMessages from './components/NoLoansMessages'

const LoansInfo = (props) => {
  const dispatch = useDispatch()
  const user = useSelector(({ auth }) => auth.user)
  const { isLoading, didError, data: loansData } = useSelector(({ loanUser }) => loanUser)
  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  useEffect(() => {
    if (user && user.id) {
      dispatch(getLoanByUser(user.id))
    }
  }, [dispatch, user, user.id])

  const redirect = () => {
    const state = {
      from: "/user-view"
    }
    props.history.push("/home", state)
  }

  return (
    <Container>
      <BoxHeader>
        <Typography align="center" variant="h6" style={{ fontWeight: '700', height: '100vh' }}>
          PRESTAMOS SOLICITADOS
        </Typography>
        {loansData && loansData?.length === 0 && (
          <Box align="center">
            <Button onClick={redirect} size="small" variant="contained" color="secondary">
              SOLICITAR PRÉSTAMO
          </Button>
          </Box>
        )}
      </BoxHeader>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={100} color="primary" />
        </div>
      ) : !didError ? (
        <LoansWrapper largeView={loansData && loansData.length > 0 ? true : false}>
          {loansData?.length > 0 ? (
            loansData.map((loan, index) => <LoanItem key={`${index}loanItem`} loanData={loan} />)
          ) : (
            <NoLoansMessages
              principalMsg={(!loansData || loansData.length === 0) && 'SIN PRÉSTAMOS'}
              secondaryMsg={(!loansData || loansData.length === 0) && 'SOLICITALO AHORA DE MODO RÁPIDO Y SEGURO'}
            />
          )}
        </LoansWrapper>
      ) : (
        <NoLoansMessages
          principalMsg={'UPS! ALGO NO FUNCIONÓ BIEN'}
          secondaryMsg={'HUBO UN ERROR AL TRAER TU INFORMACIÓN, INTENTE NUEVAMENTE MÁS TARDE'}
        />
      )}
    </Container>
  )
}

export default LoansInfo
