import styled from 'styled-components'
import { ReactComponent as QuestionsIconSVG } from 'assets/home/questions-icon.svg'

export const FaqsContainer = styled.div`
  position: relative;
  bottom: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 2rem;
    width: 100%;
  }
`

export const QuestionsIcon = styled(QuestionsIconSVG)`
  height: 50px;
  margin-bottom: 1rem;
`

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > * {
    margin-left: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    & > * {
      margin: 0;
    }
  }
`
export const ExpandPanel = styled.div`
  margin-top: 1rem;
  width: 40vw;
  & > * {
    margin-bottom: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const ExpandBtn = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
  }
  & > svg.toggle {
      transform: rotate(180deg);
  }
`
