import React from 'react'
import { useLocation } from 'react-router-dom'
import { Title, Container, SubTitle, TextsContainer, ContainerBackground, ContainerBackgroundError } from './styles'
import background from 'assets/register/person-register-ok.svg'
import backgroundError from 'assets/register/error.svg'
import { Button } from '@material-ui/core'

const ReturnUrl = (props) => {
  let { search } = useLocation()
  let query = new URLSearchParams(search)
  let sid = query.get('sid')
  let status = query.get('status')
  let uid = query.get('uid')

  return (
    <div>
      {' '}
      {status === '200' ? (
        <Container>
          <ContainerBackground background={background}>
            {/* <Nav title="VALIDAR EMAIL" /> */}
            <TextsContainer>
              <Title>SUS DATOS FUERON INGRESADOS CORRECTAMENTE</Title>
              <SubTitle></SubTitle>
              <Button variant="contained" color="secondary" onClick={() => props.history.push('/')}>
                Ir a home
              </Button>
            </TextsContainer>
          </ContainerBackground>
        </Container>
      ) : (
        <Container>
          <ContainerBackgroundError background={backgroundError}>
            <TextsContainer>
              <Title>LO SENTIMOS</Title>
              <SubTitle> No hemos podido validar su tarjeta</SubTitle>
              <Button variant="contained" color="secondary" onClick={() => window.history.go(-1)}>
                Volver a intentar
              </Button>
            </TextsContainer>
          </ContainerBackgroundError>
        </Container>
      )}{' '}
    </div>
  )
}

export default ReturnUrl
