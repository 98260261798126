import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    fontSize: '1.5rem',
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: 'inherit',
    borderRadius: 0,
    border: 0,
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))

export const LegendMessage = styled.div`
  background: #808080;
  color: #fff;
  border-radius: 25px;
  text-align: center;
  font-family: 'Montserrat';
`

export const SummaryContainer = styled.div`
  border-radius: 20px;
  background: #e6e6e6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 70%;
  width: ${({ width }) => (width ? width : '')};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
    padding: 25px;
  }
`
export const VerifyBall = styled.div`
  background: url('${({ background }) => background}');
  width: 20px;
  height: 20px;
`

export const SummaryItem = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #000;

  ${(props) =>
    props.isDefaulterCustomer &&
    css`
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      & > p {
        color: red;
      }
    `}
  ${(props) =>
    props.isFinished &&
    css`
      & > div > span,
      p {
        color: yellow;
      }
    `}
`
export const SummaryItemText = styled(Typography)`
  color: #1a1a1a;
  font-weight: 600;
`
export const PrincipalBox = styled.div`
  position: relative;
  /* margin-bottom: 10%; */
  display: flex;
  justify-content: center;
`
