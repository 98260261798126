import styled, { css } from 'styled-components'

export const GenericNav = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      z-index: 6;
      height: 65px;
    `}
`
