import { RESET_STATE, SEARCH_LOGIN_START, SEARCH_LOGIN_COMPLETE, SEARCH_LOGIN_ERROR, SIGN_OUT } from 'redux/actionTypes'

const defaultState = {
  isAuthenticated: false,
  isLoading: false,
  user: {},
  error: '',
  message: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_LOGIN_START:
      return { ...state, isLoading: true, error: '', isAuthenticated: false }
    case SEARCH_LOGIN_COMPLETE:
      return { ...state, isLoading: false, error: '', user: action.user, isAuthenticated: action.success, message: action.msg }
    case SEARCH_LOGIN_ERROR:
      return { ...state, isLoading: false, error: action.msg, isAuthenticated: false }
    case SIGN_OUT:
      return {
        ...state,
        error: action.msg,
        isAuthenticated: false,
        user: {
          user_role: {
            name: false,
          },
        },
      }
    default:
      return state
  }
}
