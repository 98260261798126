import {
  GET_LOAN_BY_USER_START,
  GET_USER_BANK_ACOUNT_COMPLETE,
  GET_USER_BANK_ACOUNT_ERROR,
  POST_USER_BANK_ACOUNT_START,
  POST_USER_BANK_ACOUNT_COMPLETE,
  POST_USER_BANK_ACOUNT_ERROR,
  RESET_STATE,
} from 'redux/actionTypes'

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
  message: '',
}

export default function bankAccountReducer(state = initialState, action) {
  switch (action.type) {
    // no estoy seguro de esto.
    case GET_LOAN_BY_USER_START:
    case POST_USER_BANK_ACOUNT_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_USER_BANK_ACOUNT_COMPLETE:
    case POST_USER_BANK_ACOUNT_COMPLETE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        hasError: false,
      }
    case GET_USER_BANK_ACOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        message: action.payload
      }

    case POST_USER_BANK_ACOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        message: action.payload
      }
    case RESET_STATE: return initialState;
    default:
      return state
  }
}
