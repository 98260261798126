import React, { useState, useEffect } from 'react'
import { Grid, Paper } from '@material-ui/core'
import {
  GeneralContent,
  FullSizeGrid,
  useStyles,
  TextContainer,
  VerifyContainer,
  VerifyBall,
  VerifyBallGray, // FIXME hacer un componente reutilizable
  ComboBox,
  AddPicButton,
  PictureContainer,
  Wrapper,
  InfoBoxesContainer,
} from './styles'

import greenBall from 'assets/img/green-ball.svg'
import grayBall from 'assets/img/gray-ball.svg'

import { useSelector, useDispatch } from 'react-redux'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'

const AccountStatus = ({ history }) => {
  const dispatch = useDispatch()
  const accountState = useSelector((state) => state.auth)
  const {
    user: { is_validated_dni, is_validated_cbu, first_name, is_validated },
  } = accountState

  const classes = useStyles()

  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  return (
    <GeneralContent className={classes.root}>
      <Wrapper container spacing={3}>
        <FullSizeGrid align="center" xs={12}>
          <Paper className={classes.paperPrimary}>
            <h4 className={classes.h4}>Hola {first_name} !</h4>
          </Paper>
        </FullSizeGrid>
        <Grid align="center" item xs={12} md={6}>
          <InfoBoxesContainer className={classes.paperSecondary}>
            {is_validated ? (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>EMAIL VALIDADO</h4>
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBall background={greenBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <p align="left">VALIDADO EXITOSAMENTE</p>
                </TextContainer>
              </>
            ) : (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>VALIDAR EMAIL</h4>{' '}
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBallGray background={grayBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <PictureContainer>
                    <AddPicButton variant="contained" color="default" onClick={() => history.push('/verificacion')}>
                      Enviar mail
                    </AddPicButton>
                  </PictureContainer>
                </TextContainer>
              </>
            )}
          </InfoBoxesContainer>
        </Grid>
        <Grid align="center" item xs={12} md={6}>
          <InfoBoxesContainer className={classes.paperSecondary}>
            {is_validated_dni ? (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>DNI VALIDADO</h4>
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBall background={greenBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <p align="left">TU DNI FUE VALIDADO EXITOSAMENTE</p>
                </TextContainer>
              </>
            ) : (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>VALIDAR DNI</h4>{' '}
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBallGray background={grayBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <PictureContainer>
                    <AddPicButton variant="contained" color="default" onClick={() => history.push('/user/perfil')}>
                      SUBIR FOTOS DEL DNI
                    </AddPicButton>
                  </PictureContainer>
                </TextContainer>
              </>
            )}
          </InfoBoxesContainer>
        </Grid>
        <Grid align="center" item xs={12} md={6}>
          <InfoBoxesContainer className={classes.paperSecondary}>
            {is_validated_cbu ? (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>CBU VALIDADO</h4>
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBall background={greenBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <p align="left">TU CBU FUE CARGADO EXITOSAMENTE</p>
                </TextContainer>
              </>
            ) : (
              <>
                <ComboBox>
                  <TextContainer dni={true}>
                    <h4 className={classes.h4}>VALIDAR CBU</h4>{' '}
                  </TextContainer>
                  <VerifyContainer>
                    <VerifyBallGray background={grayBall} />
                  </VerifyContainer>
                </ComboBox>
                <TextContainer secondaryText={true}>
                  <PictureContainer>
                    <AddPicButton variant="contained" color="default" onClick={() => history.push('/user/cbu-info')}>
                      CARGAR CBU
                    </AddPicButton>
                  </PictureContainer>
                </TextContainer>
              </>
            )}
          </InfoBoxesContainer>
        </Grid>
      </Wrapper>
    </GeneralContent>
  )
}
export default AccountStatus
