import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LoanSnackbar from './SnackbarContent'
import { Snackbar } from '@material-ui/core'
import { editNotificationAction } from 'redux/actions/notification'
import { useStyles } from './styles'

export const NotificationWrapper = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const notificationState = useSelector((state) => state.appNotificationReducer)

  const handleClose = useCallback(() => {
    dispatch(editNotificationAction({ open: false, variant: undefined, message: "" }))
  }, [dispatch])

  const handleExited = useCallback(() => {
    dispatch(editNotificationAction({ message: "" }));
  },[dispatch])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={notificationState.open}
      autoHideDuration={5000}
      className={classes.root}
      onClose={handleClose}
      onExited={handleExited}
      ClickAwayListenerProps={{ mouseEvent: false }}
    >
      <LoanSnackbar
        className={classes.content}
        variant={notificationState?.variant}
        message={notificationState?.message}
        onClose={handleClose}
      />
    </Snackbar>
  )
}