import { useEffect } from 'react'

export const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    // verifico que el click se ejecture en un elemento react cuyo ref hace referencia al pasado por parametro,
    // y además, que el target del elemento no sea el mismo al que se clickea(esto es para chequear lo que se clickea por fuera)
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

