import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme?.spacing(),
  },
  content: {
    flexWrap: "nowrap",
  },
}));
