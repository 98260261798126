import styled from 'styled-components'

export const Pill = styled.span`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 5px;
  border-radius: 14px;
  font-weight: 600;
  font-size: 10px;
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 1:
        return theme.palette.error.main
      case 2:
        return theme.palette.warning.main
      default:
        return theme.palette.success.main
    }
  }};
`
