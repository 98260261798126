import axios from 'axios'
import {
  GET_VAR_ECONOMIC_START,
  GET_VAR_ECONOMIC_COMPLETE,
  GET_VAR_ECONOMIC_ERROR,
  BASE_URL_API,
} from 'redux/actionTypes'

export const getVarEconomic = (level) => async (dispatch) => {
  dispatch({ type: GET_VAR_ECONOMIC_START })

  try {
    const { data } = await axios.get(`${BASE_URL_API}/api/info/socio-economics/descriptions/unit/${level || 'A1'}`)
    dispatch({ type: GET_VAR_ECONOMIC_COMPLETE, payload: data })
  } catch (error) {
    dispatch({ type: GET_VAR_ECONOMIC_ERROR, payload: error.status })
  }
}
