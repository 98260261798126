import {
  BASE_URL_API,
  POST_RESET_PASSWORD_COMPLETE,
  POST_RESET_PASSWORD_ERROR,
  POST_RESET_PASSWORD_START,
  RESET_STATE,
} from 'redux/actionTypes'

import axios from 'axios'

export const SendMailPassword = ({ email }) => async (dispatch) => {
  dispatch({ type: POST_RESET_PASSWORD_START })
  try {
    console.log(email)
    await axios.post(BASE_URL_API + '/api/auth/reset-password', { email })
    dispatch({ type: POST_RESET_PASSWORD_COMPLETE, payload: 'Correo enviado correctamente' })
  } catch (error) {
    dispatch({ type: POST_RESET_PASSWORD_ERROR, payload: error?.response?.data?.message })
  }
}

export const resetSendEmailPwdState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  })
}
