import React, { useState } from 'react'
import { Title, Container, SubTitle, TextsContainer, ContainerBackground, SubmitButton } from './styles'
import background from 'assets/register/person-register-ok.svg'
import RegisterFooter from 'components/RegisterFooter'
import { Button } from '@material-ui/core'
import Spinner from 'components/Spinner'
import { BASE_URL_API } from '../../redux/actionTypes'
import Axios from 'axios'
import { useNotification } from 'hooks'

const VerifyTermAndConditions = ({ match, history }) => {
  const token = match.params.token
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState({})
  const { showNotification } = useNotification()

  const AceptTermAndConditions = async () => {
    setLoading(true)
    try {
      const { data, status } = await Axios.put(`${BASE_URL_API}/api/user/acept-term-conditions/${token}`, {}, {})
      setInfo({ status, data })
      showNotification('Terminos y condiciones aceptadas', 'success')
    } catch (error) {
      setError(true)
      showNotification('No se pudo aceptar terminos y condiciones', 'error')
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner showSpinner />
  }

  if (error) {
    return (
      <Container>
        <TextsContainer>
          <Title>
            UPSS HUBO UN ERROR! <br />
            Al intentar validar los terminos y condiciones se produjo un error.
          </Title>
          <SubTitle>Por intente nuevamente mas tarde</SubTitle>
          <Button variant="contained" color="secondary" onClick={() => history?.push('/home')}>
            Ir a home
          </Button>
        </TextsContainer>
        <RegisterFooter specialHeight={true} />
      </Container>
    )
  }

  return (
    <Container>
      {info.status === 200 || info.status === 202 ? (
        <ContainerBackground background={background}>
          <TextsContainer>
            <Title>
              Gracias!!! <br />
              Los terminos y condiciones fueron aceptados correctamente.
            </Title>
            <SubTitle>Pronto se prondran en contacto con vos para avanzar con el prestamo</SubTitle>

            <Button
              style={{
                width: 200,
                position: 'absolute',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                bottom: '86px',
              }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => history?.push('/home')}
            >
              Ir a home
            </Button>
          </TextsContainer>
        </ContainerBackground>
      ) : (
        <>
          <TextsContainer>
            <br />
            <br />
            <br />
            <Title>
              {info.status === 202 || info.status === 200
                ? 'terminos y condiciones'
                : 'Acepta los terminos y condiciones del prestamo!!'}
            </Title>
            {(info.status === 202 || info.status === 200) && (
              <SubTitle>
                Gracias por aceptar los terminos y condiciones, en breve se pondran en contacto para avanzar con tu
                prestamo.
              </SubTitle>
            )}
            <div
              style={{ height: 800, overflowY: 'auto', width: 1280, marginTop: 30, marginBottom: 30, color: 'white' }}
            >
              TÉRMINOS Y CONDICIONES DEL PRÉSTAMO Previo al uso de la plataforma online de créditos se recomienda la
              lectura detenida de los Términos y Condiciones. La misma permite puntualizar las particularidades del
              servicio y las condiciones del uso y otorgamiento. Mediante nuestra web se pueden solicitar Préstamos
              Personales y además solicitar asesoramiento, a nuestro personal capacitado, ante eventuales consultas o
              dudas. La presente tiene por objeto establecer los términos y condiciones de utilización del sitio web
              https://www.creditosloan.com/, los términos y condiciones de la utilización de la plataforma y la
              solicitud de créditos personales, respecto de los usuarios del sitio y de los servicios que en ella se
              ofrecen. El usuario al ingresar la opción “Aceptar”, toma conocimiento y asiente irrevocablemente cuando
              se requiera confirmación de aceptación de “Términos y Condiciones”, ha leído y aceptado en su totalidad
              dichos términos y condiciones, quedando obligado a estricto cumplimiento de los mismos. Toda persona
              física, entre 18 y 65 años de edad, que cuenten con capacidad legal para contratar, no se encuentren
              inhabilitadas, que tengan su domicilio permanente en la República Argentina, que acepten los términos y
              condiciones y soliciten un préstamo personal, generará una relación contractual de carácter vinculante,
              obligándose a cumplir con los términos y condiciones aquí denunciados. Si Usted no está de acuerdo con
              estos términos, debe abandonar esta página y abstenerse de operar en ella. No podrán operar el sitio las
              personas incapaces en los términos del artículo 24 del Código Civil y Comercial de la Nación. Toda persona
              que acepta los términos y condiciones declara que le fue proporcionada, previamente y con suficiente
              antelación, toda la información necesaria y el debido asesoramiento de las normas vigentes y aplicables
              para la solicitud del crédito, incluyendo los artículos de la ley 24.240 de Defensa del Consumidor números
              4, 19 y 36. La utilización de los servicios de la página aquí detallados, los términos y condiciones
              legales aquí expresados son de carácter vinculante y de lectura obligatoria para el ingreso a la
              plataforma y para solicitar créditos en la misma, conjuntamente con los términos y condiciones para la
              utilización del sitio y las políticas de privacidad; que desde ya se declaran conocer y aceptar en todos
              sus términos. Se podrá requerir al Usuario el envío de cualquier documentación o antecedente adicional que
              se considere necesario a fin de poder corroborar los datos personales enviados por el Solicitante. En este
              contrato bilateral el usuario reconoce y acepta los términos y condiciones, dentro del sitio web se
              establecen las reglas de las que se pactará mediante el uso, por los contratantes, las herramientas de
              comunicación provistas por el sitio, en el que prevalecerán, como principios rectores y esenciales, la
              buena fe y el deber de colaboración mutua entre las partes. Las operaciones realizadas en LOAN, se regirán
              por las disposiciones establecidas en el presente y por las normas legales de carácter general y
              particular aplicables a la naturaleza de los actos realizados bilateralmente. La aceptación del Préstamo
              por el usuario, implicará que el Solicitante recibió, leyó, comprendió y aceptó íntegramente estos
              términos y condiciones. Requisitos para operar en LOAN: Ser argentinos naturales o por opción, mayores de
              dieciocho (18) años. Cualquier otra persona física o jurídica que LOAN autorice en un futuro. Tengan la
              capacidad legal para contratar. Sean titulares de una cuenta bancaria controlada por el Banco Central de
              la República Argentina. El usuario expresa con carácter de declaración jurada que los datos volcados en el
              sitio para la solicitud del crédito, son ciertos y manifiestan la realidad actual, que no se encuentra
              inhibido ni en estado de concurso o quiebra. Si dentro del plazo de la duración del crédito el solicitante
              ingresa en los estados mencionados precedentemente deberá notificar inmediatamente a LOAN dicha situación.
              LOAN se reserva el derecho de suspender o inhabilitar el acceso a la página al Usuario que considere. El
              Usuario que reúna los requisitos mínimos antes mencionados, deberá completar el formulario de validación
              de datos, el mismo tiene carácter de declaración jurada. El Solicitante en ese momento elegirá un Usuario
              y contraseña cumpliendo con las normas de seguridad exhibidas. El solicitante que requiera un crédito a
              través de nuestra página reconocerá y certificará la veracidad, vigencia, exactitud y autenticidad de los
              datos facilitados y se comprometerá a mantenerlos debidamente actualizados, en virtud de ello, el Usuario
              autoriza a LOAN a corroborar la autenticidad de los datos suministrados acudiendo a entidades para tal
              fin. LOAN podrá solicitar al interesado cualquier comprobante o dato adicional que considere necesario a
              efectos de corroborar y confirmar sus datos personales. Los datos falsos o que no pudieron ser verificados
              faculta a LOAN a descartar al usuario, y por consiguiente no genera ningún derecho para ambas partes. LOAN
              podrá rechazar las Solicitudes de Créditos a su exclusivo criterio. CONDICIONES GENERALES Aquí se
              establece los términos y condiciones para el uso del sitio web www.creditosloan.com. El Usuario al crear
              una cuenta en LOAN reconoce que ha leído, entendido y acepta que quedará vinculado por los términos del
              contrato entre partes, por lo que deberá cumplir con todas las leyes y reglamentos aplicables. LOAN
              manifiesta que se reserva el derecho a cambiar el presente acuerdo en cualquier momento, notificando vía
              e-mail o a través del Sitio Web. Todos los cambios entrarán en vigencia después de su publicación. Por
              ello, el tomador del crédito se compromete a hacerse cargo de todos los gastos y cargos en general sobre
              el producto requerido que sean determinados en el Sitio, asimismo, declara conocer y aceptar dichos
              gastos, los términos y condiciones. También declara y acepta que todos los impuestos, comisiones, gastos,
              costas, tasas creadas en el futuro por el Gobierno Nacional, Provincial o Municipal y que graven los
              productos, se añadirán a las cuotas mensuales y será a exclusivo cargo del solicitante. El uso de la
              página web a posteriori de la modificación, será entendido como la conformidad y aceptación de dichos
              cambios. LOAN puede modificar, restringir, inhabilitar y hasta suspender el acceso al sitio web sin aviso
              previo. El Usuario que no reconoce y acepta estos términos y condiciones, los cuales tienen un carácter
              obligatorio y vinculante, no podrá ingresar al Sitio y solicitar créditos en él ofrecidos. El Usuario se
              obliga a interactuar en el Sitio sobre la base del principio de buena fe y el deber de colaboración entre
              las partes. El Usuario debe leer, entender y aceptar, todas las condiciones establecidas en los términos y
              condiciones y en las políticas de privacidad, así como en los demás documentos incorporados a los mismos,
              previo a la creación de la cuenta en el Sitio. El solicitante entiende que LOAN no ofrece asesoramiento
              legal o impositivo alguno por los servicios solicitados en el sitio, por lo que el tomador, en caso de
              corresponder, deberá requerirlo en forma particular a su costa. Todos los derechos no conferidos
              expresamente aquí en los términos y condiciones son reservados. El acuerdo entre las partes estará
              finalizado cuando se haya cancelado la totalidad de las cuotas del crédito solicitado o cuándo LOAN
              disuelva el acuerdo a libre albedrio. Todo lo volcado aportado en opiniones, comentarios, o cualquier idea
              plasmada en el sitio serán propiedad de LOAN, por lo que se podrá usar el mismo para cualquier interés de
              LOAN, sin limitación o resarcimiento alguno. LOAN no tiene ninguna obligación de confidencialidad con
              respecto a las comunicaciones, salvo que se acuerde expresamente con LOAN, o según la ley aplicable. Nada
              de lo aquí contenido se interpretará como una limitación de las responsabilidades y obligaciones de LOAN
              en su Política de Privacidad. El Usuario acepta indemnizar y proteger a LOAN y se obliga a cooperar, por
              cualquier reclamo, pérdidas, gastos, demandas o responsabilidades, incluidos los honorarios y costos de
              abogados, incurridos en relación con cualquier reclamo de publicaciones que haga en el sitio, o viole
              cualquier ley a través de este sitio. En ningún caso LOAN, sus directores y su personal será responsable
              por cualquier daño, directo o indirecto, o cualquier otro daño que se produzca por acción del acuerdo o
              negligencia derivada del uso o de su impedimento en el uso relacionado a una falla, interrupción,
              desperfecto, demoras en el funcionamiento por problemas en la línea, del sistema o virus, incluso cuando
              las partes estén al tanto de que estos inconvenientes puedan generar pérdidas o gastos. El solicitante
              aceptando los términos y condiciones queda obligado a informar de todo cambio que tenga de la información
              suministrada o cargada a través del sitio, como ser el cambio de domicilio, correo electrónico, situación
              laboral o económica. Deberá comunicarse con LOAN vía correo electrónico a admin@creditosloan.com o
              cualquier otra dirección que informe LOAN en el futuro. El Usuario al solicitar créditos a través del
              sitio, da su consentimiento que toda información y comunicación será en el formato electrónico, y todas
              las operaciones serán dentro de la plataforma del Sitio. Los usuarios de LOAN se obligan a cancelar sus
              obligaciones en las fechas establecidas en el Sitio de LOAN, aceptando, en caso de no cumplir con el
              acuerdo, la aplicación de intereses compensatorios y punitorios según se haya pactado. PROCESO DEL
              PRÉSTAMO Una vez ingresado al sitio el Solicitante deberá ingresar los datos requeridos en crear cuenta. A
              los fines de validar su identidad a través de métodos de inteligencia artificial, deberá enviar: Foto del
              frente de su DNI, Foto del dorso de su DNI, CBU de cuenta bancaria (número de 22 dígitos) en la cual
              recibe su salario u honorarios de su titularidad en un banco de la República Argentina, y correo
              electrónico. Una vez enviadas los datos, el Solicitante deberá seleccionar el monto del crédito a
              solicitar y el plazo en meses. Vía e-mail o a través de la plataforma se le notificará en caso de
              calificar o no para el otorgamiento del préstamo. El perfeccionamiento del préstamo se efectivizará con la
              aceptación de los términos y condiciones por parte del usuario. Si calificó se transferirá el monto
              solicitado y aprobado a la cuenta bancaria declarada por el usuario en la solicitud de préstamo. El
              solicitante aceptará los tiempos y demoras que puedan surgir en la acreditación en su cuenta, como ser los
              días y horarios, los retrasos en las operaciones bancarias y cualquier otra situación o caso fortuito o de
              fuerza mayor. En virtud de ello, el Cliente acepta y entiende las demoras y renuncia a cualquier tipo de
              reclamo por cualquier daño o perjuicio relacionado con la dilación en la acreditación del préstamo
              aprobado. El préstamo se cancelará con los términos y condiciones aceptadas y pactadas por las partes al
              momento de solicitar el préstamo. El vencimiento de la primera cuota se producirá del 1 al 10 del mes
              siguiente al desembolso del préstamo, y las restantes a partir del 1 al 10 de cada mes subsiguiente. Si
              alguno de los días de pago establecidos resulta inhábiles, el vencimiento de los mismos se producirá el
              primer día hábil posterior. El Solicitante acepta expresamente que la falta de pago de una cuota implicará
              la caducidad de todos los plazos concedidos para el pago de cuotas, considerándose en tal caso como
              íntegramente vencida y exigible en su totalidad el saldo adeudado. Asimismo, se producirá la extinción de
              todos los plazos concedidos para el pago del Préstamo, estando obligado a cancelar la totalidad de las
              obligaciones procedentes de los términos y condiciones pendientes de pago cuando se detecte la
              modificación de la cuenta bancaria, de alguna información presentada, o el desconocimiento malintencionado
              del medio de pago pactado, solicitando el Stop Debit, baja o reversión de los pagos comprometidos,
              representará un incumplimiento ineludible a las obligaciones del solicitante, por lo que permitirá a LOAN
              iniciar las acciones legales civiles y penales pertinentes. Todo litigio que surja con motivo de este
              acuerdo entre las partes serán competencia exclusiva de los Tribunales Nacionales en lo Comercial con
              asiento en el domicilio informado por el solicitante a LOAN, el usuario renuncia a todo otro fuero y/o
              jurisdicción que pudiera corresponder, constituyéndose domicilio especial a todo suceso en el Sitio. En
              caso de incurrir en mora, el Solicitante se obliga a pagar, además del interés compensatorio, un interés
              adicional en carácter de punitorio, equivalente al 0,50% diario, mientras dura la mora y hasta la
              cancelación total de la deuda, independientemente de los cargos y comisiones que se generen producto del
              estado de mora. En los términos de lo dispuesto por el artículo 770 inc. a) del Código Civil y Comercial
              de la Nación, el Solicitante acepta expresamente que los intereses compensatorios y punitorios se
              capitalizarán con una periodicidad no inferior a seis meses y se acumularán al capital adeudado. La
              omisión o demora en el ejercicio por parte del Acreedor de cualquier derecho o privilegio emergente de
              esta solicitud, no podrá, en ningún caso, considerarse como una renuncia al mismo, así como su ejercicio
              parcial no impedirá complementarlo posteriormente ni enervará el ejercicio de cualquier otro derecho o
              privilegio. Los pagos parciales o entrega de dinero a cuenta, luego de operada la mora, no implicarán en
              ningún caso quita, espera, remisión o novación de la obligación o acciones, aún en caso de que estos pagos
              fueran posteriores a la demanda, proseguirán su curso por el saldo de capital, intereses o costas en el
              siguiente orden: 1º Impuestos, Cargos y comisiones de existir; 2º intereses punitorios; 3º intereses
              compensatorios y, por último, a capital. La falta de cumplimiento de la obligación de informar cualquier
              cambio en la cuenta, será considerada una falta grave de las obligaciones del presente y permitirá a LOAN,
              sin necesidad de comunicación previa ni interpelación alguna, considerar al Préstamo como de plazo vencido
              y exigir por la vía correspondiente su pago total. No obstante, en caso que no pudiera efectuarse por el
              medio arriba indicado, el Acreedor podrá utilizar alguno de los siguientes mecanismos: A través de la
              contratación con una entidad intermedia y demás accesorios que prevea la normativa vigente. Sin perjuicio
              del mecanismo de pago que sea aplicable en virtud del tipo de Préstamo solicitado, en este acto el
              Solicitante autoriza al Acreedor, para que, frente a alteraciones en el pago de las cuotas y accesorios,
              establezca como mecanismo de pago alternativo y a su exclusiva elección, sin necesidad de notificación
              previa, cualquiera de los demás mecanismos enunciados precedentemente. El Solicitante reconoce que toda
              entidad, distinta del Acreedor, que realice la retención de sus haberes o los débitos directos de su
              cuenta bancaria, se trata de una mandataria designada por el acreedor a estos efectos. En tal supuesto,
              autoriza expresamente al Acreedor para que emita las instrucciones que correspondan, previa notificación
              de tal hecho al Solicitante por cualquier medio dispuesto a tal fin por aquél. Si eventualmente la entidad
              no abonara al Acreedor los importes retenidos o debitados en concepto de cuotas u otros accesorios, la
              obligación de integración de los pagos de las obligaciones convenidas continuarán a cargo del Solicitante.
              Lo dispuesto precedentemente, sin perjuicio de la utilización que pueda hacer el Acreedor, a su criterio,
              de cualquier otro mecanismo de pago para el que desde ya el Solicitante ha autorizado expresamente
              mediante la aceptación de la presente. En caso de que encontrándose en relación de dependencia se
              produjese por cualquier motivo la desvinculación laboral y no se pudieran pagar las cuotas mensuales del
              Préstamo, el Solicitante se obliga a abonarlas o a instruir su pago con la liquidación final de la
              relación laboral interrumpida o en su defecto donde el Acreedor lo indique en el futuro. El usuario
              constituirá, de forma obligatoria, un seguro de vida sobre saldo deudor en su favor, a su exclusivo cargo.
              Los seguros estarán vigentes el tiempo que dure el crédito hasta su cancelación total y estarán dentro de
              las cuotas mensuales de los créditos concedidos. LOAN queda desde ahora expresamente autorizado a ceder
              total o parcialmente el Préstamo y sus accesorios otorgados en propiedad, propiedad fiduciaria o en
              garantía a terceros, sin restricciones de ningún tipo ni autorización en particular y sin necesidad de
              notificación al deudor cedido. Las Partes expresamente acuerdan que todos los derechos a favor de LOAN,
              conforme al presente, podrán adicionalmente ser cedidos sin necesidad de notificación, de conformidad con
              lo que establecen los artículos 70 y 72 de la Ley 24.441, cuando tal cesión tuviera por objeto; (1)
              garantizar la emisión de títulos valores mediante oferta pública; (2) constituir el activo de una sociedad
              con el objeto que emita títulos valores ofertables públicamente y cuyos servicios de amortización e
              intereses estén garantizados con dicho activo; y/o (3) constituir el patrimonio de un fondo común de
              Préstamos. Todos los gastos que se deban incurrir para el pago del Préstamo, serán a cargo del
              Solicitante. También son a su exclusivo cargo todo impuesto presente o futuro, costos, costas, comisiones,
              tasas de cualquier naturaleza que existan o fuesen creadas en el futuro por el Gobierno Nacional o
              Provincial y que graven el mutuo, sus cuotas e intereses, los cuales se adicionarán a las cuotas
              mensuales. Para el caso de que el SOLICITANTE resolviera cancelar en forma anticipada el PRÉSTAMO, (las
              cuotas se cancelan de atrás hacia adelante total o parcialmente), éste se obliga a abonar una comisión por
              la devolución anticipada de los fondos solicitados, cuyo monto será del 40% sobre el CAPITAL adeudado a
              cancelar. Dichos montos serán aplicados, a reducir el número de cuotas. Conjuntamente con cada cancelación
              anticipada, el SOLICITANTE deberá abonar la comisión por cancelación anticipada, vigente en ese momento
              sobre el saldo adeudado. Tanto en los supuestos de precancelaciones totales como parciales, el SOLICITANTE
              asume expresa a irrevocablemente la obligación de proveer de manera previa a cada precancelación toda la
              información y documentación que LOAN requiera a los efectos de demostrar que los fondos destinados a la
              precancelación, total o parcial, del PRÉSTAMO son de origen lícito. El Solicitante autoriza a LOAN a
              informar a las empresas de riesgo crediticio, el estado del Préstamo solicitado, ello sin que implique
              violación alguna a los derechos amparados por la Ley 25.326 de Protección de Datos Personales, sus
              complementarias y modificatorias. El consentimiento libre otorgado por el Solicitante en la presente
              cláusula se brinda a los efectos de la Ley 25.326 de Protección de Datos Personales, sus modificatorios y
              complementarios. Responsabilidad del Solicitante por incumplimiento. El Solicitante mantendrá indemne a
              LOAN por cualquier daño provocado por su incumplimiento a estos términos y condiciones, incluyendo, sin
              que implique limitación, el suministro de información falsa que pudiera derivar en perjuicio para
              terceros. Toda la información de carácter personal del Solicitante será tratada por LOAN de acuerdo con la
              normativa vigente, en particular, en cumplimiento de la Ley 25.326 de Protección de Datos Personales y su
              decreto reglamentario. El Solicitante declara conocer que la aceptación de los términos y condiciones
              implica haber leído y aceptado los términos y condiciones. En cumplimiento de las leyes y normas
              particulares emanadas de la Unidad de Información Financiera (UIF), las que el Solicitante declara conocer
              y aceptar, manifiesta con carácter de declaración jurada que la información y datos identificatorios
              proporcionados y demás documentación aportada, son exactos y verdaderos; que los fondos y valores a
              utilizar para el repago de las obligaciones contempladas en estos términos y condiciones tienen su origen
              en actividades lícitas; que en caso de haberse presentado documentación respaldatoria la misma es copia
              fiel del original; y que no se encuentra incluido y/o alcanzado por la “Nómina de Personas Expuestas
              Políticamente” aprobada por Res. 134/2018 de la Unidad de Información Financiera, sus complementarias y/o
              modificatorias, que desde ya declara conocer. De acuerdo a lo establecido en el art. 20 de la ley 25.246 y
              modificatorias, declara que no es Sujeto Obligado. El Solicitante asume el compromiso de informar
              cualquier modificación que se produzca a este respecto, dentro de los treinta (30) días de ocurrida,
              mediante la presentación de una nueva declaración jurada. POLÍTICA DE PRIVACIDAD, DATOS PERSONALES E
              INFORMACIÓN A TERCEROS El Solicitante presta su expreso consentimiento, libre e informado, de conformidad
              con la Ley de Protección de Datos Personales para que LOAN o quién éste designe o a quién ceda sus
              derechos del presente préstamo: Procese, almacene y trate en forma automatizada los datos de carácter
              personal obtenidos en razón del otorgamiento del préstamo, inclusive y sin que ello resulte taxativo, toda
              fotografía de rostro destinada a validar la identidad y vigencia de su Documento Nacional de Identidad así
              como cualquier otro dato que en el futuro pudiere aportar el Solicitante u obtener LOAN con el objeto de
              utilizarlo para cuantas gestiones se deriven directa o indirectamente del préstamo, así como con la
              finalidad de que el Solicitante pueda recibir información publicitaria sobre productos, ofertas y
              promociones especiales. Otras Declaraciones. Con carácter de Declaración Jurada el solicitante expresa que
              los datos aportados son verídicos y responden a la realidad. Como así mismo que no se encuentra inhibido
              ni en estado de concurso o quiebra comprometiéndose a notificar fehacientemente y de inmediato a LOAN
              cualquier cambio que se produjera en los mismos. Asimismo, declara bajo juramento que se compromete a no
              tomar otros préstamos u otro tipo de asistencias que puedan afectar el pago de este Préstamo durante la
              totalidad de su período de vigencia. En caso de incumplimiento se hace responsable de los daños y
              perjuicios que pudiera ocasionar a LOAN. El solicitante se notifica por la presente que le asiste el
              derecho a requerir de LOAN la respectiva constancia de saldo de deuda con el debido detalle de los pagos
              efectuados. NOTIFICACIONES Las notificaciones entre las Partes que deban realizarse como consecuencia de
              los presentes términos y condiciones se realizarán por correo electrónico en los domicilios mencionados a
              continuación para cada una de las Partes: admin@creditosloan.com Solicitante: en el correo electrónico
              informado al solicitar el Préstamo. LOAN: Aceptación de los términos y condiciones. La utilización del
              servicio prestado por LOAN implicará la aceptación de pleno derecho de todas y cada una de las
              disposiciones previstas en los presentes términos y condiciones, sin reservas. Ley Aplicable y
              Jurisdicción El Préstamo se interpretará y regirá de conformidad con las leyes de la República Argentina.
              Serán competentes para resolver cualquier reclamo o controversia que pudiera plantearse en relación con la
              validez, interpretación o cumplimiento del Préstamo, los tribunales ordinarios correspondientes al
              domicilio del Solicitante. Revocación de aceptación. En los casos previstos en los artículos 32 y 33 de la
              Ley 24.240, el Solicitante tiene derecho a revocar la aceptación durante el plazo de diez (10) días
              corridos contados a partir de la fecha en que se entregue el bien o se celebre el contrato, lo último que
              ocurra, sin responsabilidad alguna. En este supuesto deberá reintegrar los fondos desembolsados con más
              los intereses correspondientes. Asimismo, obra como constancia del asentimiento expreso la remisión vía
              correo electrónico y/o cualquier otro medio que considere el Acreedor de la información correspondiente al
              presente Préstamo (Art 4° Ley de Defensa al Consumidor). El Solicitante reconoce expresamente que, previo
              a solicitar este Préstamo, se ha asesorado debidamente y ha tenido en cuenta la eventualidad de una futura
              alteración de las variables económicas del país y sus consecuencias en el repago del mismo al Acreedor,
              por lo que asume dichas consecuencias y, en caso de ocurrir tal circunstancia en el futuro, se estará a lo
              convenido en la presente. El Solicitante se notifica de que toda consulta que quiera realizar, la podrá
              hacer por correo electrónico a admin@creditosloan.com o por teléfono al Servicio de Atención al
              Solicitante al siguiente número +549 11 5970-7620 (WhatsApp) durante las 24 hs. Utilización de los
              Productos ofrecidos. Los Usuarios, visitantes o clientes de LOAN que utilicen los productos o servicios
              ofrecidos en el Sitio, deberán ajustarse a las disposiciones presentes y a las condiciones generales que
              rigen para su uso, los contratos particulares que rigen las operaciones y todas las disposiciones y normas
              generales y particulares aplicables, que todo usuario declara conocer y aceptar en su totalidad. Los
              Usuarios, visitantes o clientes que ingresen al Sitio o a las páginas principales o accesorias del Sitio,
              prestan con su sólo ingreso conformidad para que LOAN: 1) proporcione informes vía internet o red privada
              de datos, 2) ofrezca productos de cualquier característica, realice transacciones autorizadas sobre sus
              cuentas, tarjetas, operaciones y servicios de todo tipo, que LOAN mantenga disponible para su utilización,
              bajo las presentes condiciones y las Condiciones Generales para la Utilización de todos los servicios
              ofrecidos. Las operaciones realizadas y perfeccionadas sobre productos o servicios disponibles que los
              visitantes Usuarios ordenen expresamente y cuenten con aprobación o confirmación por parte de LOAN, no
              podrán revocarse bajo ninguna forma o medio. Todo tipo de clave de acceso o código de utilización de
              productos o servicios disponibles o de acceso al Sitio o a las páginas principales o accesorias al Sitio,
              que aquel provea a los visitantes, usuarios, es de carácter estrictamente secreta y confidencial y de uso
              exclusivo de los visitantes, usuarios o clientes. Por consiguiente, está prohibida toda transferencia,
              divulgación o entrega de esas claves o códigos a personas distintas de sus titulares o beneficiarios. Toda
              entrega no autorizada de claves o códigos será exclusiva responsabilidad de sus titulares o beneficiarios.
              LOAN podrá hacer cambios en cualquier momento y sin autorización previa ni previo aviso a los usuarios, en
              el contenido y el sitio y sin responsabilidad alguna para con el Usuario. Las partes no tienen obligación
              o responsabilidad de actualizar o modificar cualquier información. Las partes se reservan el derecho a
              terminar cualquier o todas las ofrendas del sitio o transmisiones sin previo aviso al usuario. Este Sitio
              puede contener errores técnicos o tipográficos. El uso de este Sitio es bajo su propio riesgo y
              responsabilidad, dado que nosotros no hacemos recomendación expresa sobre las decisiones de crédito
              tomadas por los usuarios. RECLAMOS Los Reclamos y quejas se plasmarán en el Sitio por parte del usuario,
              cumpliendo lo establecido en el Artículo 27 de la Ley 24.240 de Defensa del Consumidor. O también a través
              del correo electrónico admin@creditosloan.com. Todo reclamo o queja, generará un número de
              registro/reclamo para llevar el seguimiento del mismo por ambas partes. LOAN responderá para evacuar
              cualquier duda o reclamo en el transcurso de los 10 (diez) días hábiles de su presentación. CONTACTATE CON
              NOSOTROS Para consultas y evacuar dudas comunícate con nosotros a través del correo electrónico
              admin@creditosloan.com.
            </div>
          </TextsContainer>
          <Button
            onClick={() => AceptTermAndConditions()}
            variant="contained"
            color="secondary"
            disabled={info.status === 202 || info.status === 200}
          >
            Aceptar
          </Button>{' '}
        </>
      )}
      <RegisterFooter specialHeight={true} />
    </Container>
  )
}

export default VerifyTermAndConditions
