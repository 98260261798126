import React, { useState, useEffect } from 'react'
import { Container, ContainerBackground, SubTitle, TextsContainer, Title } from './styles'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@material-ui/core'
import RegisterFooter from 'components/RegisterFooter'
import background from 'assets/register/person-register-ok.svg'
import Axios from 'axios'
import { BASE_URL_API } from 'redux/actionTypes'
import Spinner from 'components/Spinner'

import { useNotification } from 'hooks/useNotification'

const VerifyEmail = ({ history, location }) => {
  const [userInfo, setUserInfo] = useState({ email: '', first_name: '' })
  const { isAuthenticated, isLoading, user } = useSelector(({ auth }) => auth)
  const [isLoandingSendEmail, setIsloandingSendEmail] = useState(false)
  const { showNotification, closeNotification } = useNotification()

  useEffect(() => {
    async function sendMail() {
      if (Object.keys(user).length === 0 && !location.state) {
        history.push('/')
      } else if (!user?.is_validated) {
        setIsloandingSendEmail(true)
        try {
          const token = localStorage.getItem('jwt')
          const config = {
            headers: { authorization: token },
          }
          await Axios.post(`${BASE_URL_API}/api/email/validate/account`, { email: user.email }, config)
          setIsloandingSendEmail(false)
        } catch (error) {
          showNotification('Error al enviar Email, intente nuevamente', 'error')
          history.push('/user/estado-de-cuenta')
          setIsloandingSendEmail(false)
        }
      }
    }
    sendMail()
  }, [history, isAuthenticated, location.state, showNotification, user, user.email, user.is_validated, user.name])

  return (
    <Container>
      <Spinner showSpinner={isLoandingSendEmail}></Spinner>
      {user?.first_name && user?.email && (
        <ContainerBackground background={background}>
          {/* <Nav title="VALIDAR EMAIL" /> */}
          <TextsContainer>
            <Title>
              HOLA {user.first_name}! <br />
              VALIDÁ TU E-MAIL Y PEDÍ A TU PRÉSTAMO HOY MISMO
            </Title>
            <SubTitle>Hemos enviado un mail de confirmación a {user.email}</SubTitle>
            <Button variant="contained" color="secondary" onClick={() => history?.push('/home')}>
              Ir a home
            </Button>
          </TextsContainer>
          <RegisterFooter specialHeight={true} />
        </ContainerBackground>
      )}
    </Container>
  )
}

VerifyEmail.propTypes = {}

export default VerifyEmail
