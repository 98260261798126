import styled from 'styled-components'

export const MainContent = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 800;
  padding: 2rem;
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
`
