import { CREATE_LOAN_COMPLETE, CREATE_LOAN_ERROR, CREATE_LOAN_START, RESET_STATE } from 'redux/actionTypes'

const defaultState = {
  hasError: false,
  isLoading: false,
  msg: '',
  statusCode: null,
}
const loan = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CREATE_LOAN_START:
      return { ...state, isLoading: true }
    case CREATE_LOAN_COMPLETE:
      return { ...state, isLoading: false, hasError: false, msg: payload.message, statusCode: payload.status }
    case CREATE_LOAN_ERROR:
      return { ...state, isLoading: false, hasError: true, msg: payload.data.detailMessage, statusCode: payload.status }
    case RESET_STATE:
      return defaultState
    default:
      return state
  }
}

export default loan
