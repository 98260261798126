import {
  BASE_URL_API,
  RESET_STATE,
  SEARCH_SIGN_UP_COMPLETE,
  SEARCH_SIGN_UP_ERROR,
  SEARCH_SIGN_UP_START,
} from 'redux/actionTypes'

import axios from 'axios'

export const actionSignUp = (data) => async (dispatch) => {
  dispatch({ type: SEARCH_SIGN_UP_START })
  try {
    const response = await axios.post(BASE_URL_API + '/api/auth/signup', data)
    dispatch({
      type: SEARCH_SIGN_UP_COMPLETE,
      message: 'Usuario creado exitosamente',
      isSuccess: response.data.success,
    })
  } catch (error) {
    dispatch({
      type: SEARCH_SIGN_UP_ERROR,
      message: 'Error al crear el usuario. \n El email ya existe.',
    })
  }
}

export const resetSignUpState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  })
}
