import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editNotificationAction } from "redux/actions/notification";

export const VARIANT = ["success", "error", "info", "warning"]


export const useNotification = (onCloseCallback) => {
    const dispatch = useDispatch();

    const notificationState = useSelector(state => state.appNotificationReducer);
    useEffect(() => {
        if (!notificationState.open) {
            onCloseCallback && onCloseCallback();
        }
        /* 
        Si añadimos onCloseCallback como dependencia, podría darse un loop infinito. FIX THIS
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationState.open]);

    const showNotification = useCallback(
        (message, variant) => {
            if (VARIANT.includes(variant) && message) {
                dispatch(editNotificationAction({ open: true, message, variant }));
            } else console.warn("No se especificó una variante válida y/o mensage.");
        },
        [dispatch]
    );

    const closeNotification = () => {
        notificationState.open &&
            dispatch(
                editNotificationAction({ open: false, message: "", variant: undefined })
            );
    }

    return {
        showNotification,
        closeNotification,
        notificationState,
    };
};
