import { GET_SCORING_START, GET_SCORING_COMPLETE, GET_SCORING_ERROR } from 'redux/actionTypes'

const defaultState = {
  isSuccess: null,
  isLoading: false,
  scoring: {},
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_SCORING_START:
      return { ...state, isLoading: true }
    case GET_SCORING_COMPLETE:
      return { ...state, isLoading: false, isSuccess: payload.success, scoring: payload.data[0] }
    case GET_SCORING_ERROR:
      return { ...state, isLoading: false, isSuccess: false }
    default:
      return state
  }
}
