import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Grid } from '@material-ui/core'
import background from 'assets/register/Group-95.svg'
import Nav from 'components/Nav'
import RegisterFooter from 'components/RegisterFooter'
import { useNotification } from 'hooks/useNotification'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { SendMailPassword, resetSendEmailPwdState } from 'redux/actions/modifyPassword/SendMailPassword'
import * as yup from "yup"
import {
  Action,
  Ad,
  BackgroundPerson,
  Bond,
  Column,
  CustomTextField,
  Divider,
  FooterContainer,
  Form,
  MiddleContainer,
  SubmitButton
} from './styles'

const ModifyPassword = ({ history }) => {

  const schema = yup.object().shape({
    email: yup.string().email("Por favor, ingrese un formáto de email válido").required("El email es obligatorio"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })
  const [valueMail, setValueMail] = useState({
    email: ''
  })

  const { isSuccess, isLoading, error, message } = useSelector((state) => state.modifyPassword)
  const { showNotification } = useNotification()

  const dispatch = useDispatch()

  const handleChange = (e) => {
    setValueMail({ [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (!!error) {
      showNotification(message, "error");
      dispatch(resetSendEmailPwdState())
    }
  }, [dispatch, error, message, showNotification])

  useEffect(() => {
    if (isSuccess) showNotification(message, "success");
  }, [isSuccess, message, showNotification])

  const isDisabled = Object.values(errors).length > 0;

  const onSubmit = (_, e) => {
    if (isDisabled) return;
    e.preventDefault()
    dispatch(SendMailPassword(valueMail))
  }

  return (
    <>
      <Nav title="RECUPERAR CONTRASEÑA" isFixed={true} isLogin={true} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid md={6}>
          <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Column>
              <CustomTextField
                id="standard-basic1"
                label="Ingrese su mail"
                name="email"
                onChange={(e) => handleChange(e)}
                type="email"
                inputRef={register}
              />
              {errors?.email && <label style={{ color: 'red' }}>{errors.email.message}</label>}
            </Column>
            {/* <MsgError>{error}</MsgError>
            <MsgSucces>{isSuccess}</MsgSucces> */}
            {isLoading ? (
              <CircularProgress size={100} color="primary" />
            ) : (
                <SubmitButton type="submit" variant="contained" color="primary" disabled={isDisabled}>
                  Enviar Mail
                </SubmitButton>
              )}
          </Form>
          <Divider />
        </Grid>
      </div>
      <MiddleContainer>
        <BackgroundPerson src={background}>
          <Ad variant="h5" align="center" gutterBottom>
            ¿Aun no tenes cuenta?
          </Ad>

          <div align="center">
            <Bond to="/registrarse">
              <Action size="large" variant="contained" color="secondary">
                CREAR CUENTA
              </Action>
            </Bond>
          </div>

          <FooterContainer>
            <RegisterFooter />
          </FooterContainer>
        </BackgroundPerson>
      </MiddleContainer>
    </>
  )
}

export default ModifyPassword
