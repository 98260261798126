import { NOTIFICATION_EDIT } from 'redux/actionTypes'

const initState = {
    open: false,
    message: "",
    variant: undefined,
};

export const appNotificationReducer = (
    state = initState,
    action
) => {
    switch (action.type) {
        case NOTIFICATION_EDIT:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};