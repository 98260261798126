import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Grid } from '@material-ui/core'
import background from 'assets/register/Group-95.svg'
import Nav from 'components/Nav'
import RegisterFooter from 'components/RegisterFooter'
import { useNotification } from 'hooks/useNotification'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { actionSignIn, verifyAuthByToken, resetSignInState } from 'redux/actions/auth/signIn.js'
import * as yup from 'yup'
import {
  Action,
  Ad,
  BackgroundPerson,
  Bond,
  Column,
  CustomTextField,
  Divider,
  FooterContainer,
  Form,
  MiddleContainer,
  SubmitButton,
  SubTitle,
} from './styles'

const schema = yup.object().shape({
  password: yup.string().required('La contraseña es obligatoria'),
  email: yup.string().email('Por favor, ingrese un formáto de email válido').required('El email es obligatorio'),
})

const Login = ({ history, ...props }) => {
  const [inputsValues, setInputs] = useState({ email: '', password: '' })
  const { isLoading, error: authErrorMessage, isAuthenticated, message: authMessage } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const isDisabled = Object.values(errors).length > 0
  const { showNotification } = useNotification(() => {})

  const handleChange = (e) => {
    setInputs({
      ...inputsValues,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const onSubmit = async (_, e) => {
    if (isDisabled) return
    e.preventDefault()
    await dispatch(actionSignIn(inputsValues))
    history.push({ pathname: '/home', state: { detail: 'fromLogin' } })
  }

  const animateTo = (elementId) => {
    const element = document.getElementById(elementId)
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    animateTo('header')
  }, [])

  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  useEffect(() => {
    !!authErrorMessage && showNotification(`Error al inciar sesión. \n ${authErrorMessage}`, 'error')
  }, [authErrorMessage, showNotification])

  useEffect(() => {
    if (isAuthenticated) {
      showNotification(authMessage, 'success')
    }
  }, [authMessage, dispatch, history, isAuthenticated, showNotification])

  return (
    <>
      <Nav title="INICIAR SESION" isFixed={true} isLogin={true} />
      <div id="header" style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid md={6} style={{ width: '100%' }}>
          <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Column>
              <CustomTextField
                id="standard-basic"
                label="E-mail"
                inputRef={register}
                name="email"
                onChange={(e) => handleChange(e)}
              />
              {errors?.email && <label style={{ color: 'red' }}>{errors.email.message}</label>}
              <CustomTextField
                id="standard-basic2"
                label="Contraseña"
                name="password"
                type="password"
                inputRef={register}
                onChange={(e) => handleChange(e)}
                required
              />
              {errors?.password && <label style={{ color: 'red' }}>{errors.password.message}</label>}
            </Column>

            {isLoading ? (
              <CircularProgress size={100} color="primary" />
            ) : (
              <SubmitButton type="submit" variant="contained" color="primary" disabled={isDisabled}>
                INGRESAR A MI CUENTA
              </SubmitButton>
            )}
          </Form>
          <Bond to="/recuperar-cuenta">
            <SubTitle>RECUPERAR CONTRASEÑA</SubTitle>
          </Bond>
          <Divider />
          {/* <SocialNetwork variant="h1">O INGRESÀ CON TU CUENTA DE FACEBOOK O GOOGLE</SocialNetwork>
          <ContainerSocialNetwork>
            <Google />
            <Facebook />
          </ContainerSocialNetwork> */}
        </Grid>
      </div>
      <MiddleContainer>
        <BackgroundPerson src={background}>
          <Ad variant="h5" align="center" gutterBottom>
            ¿Aun no tenes cuenta?
          </Ad>

          <div align="center">
            <Bond to="/registrarse">
              <Action size="large" variant="contained" color="secondary">
                CREAR CUENTA
              </Action>
            </Bond>
          </div>

          <FooterContainer>
            <RegisterFooter />
          </FooterContainer>
        </BackgroundPerson>
      </MiddleContainer>
    </>
  )
}

export default Login
