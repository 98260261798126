import axios from 'axios'
import {
  BASE_URL_API,
  GET_USER_BANK_ACOUNT_START,
  GET_USER_BANK_ACOUNT_COMPLETE,
  GET_USER_BANK_ACOUNT_ERROR,
  POST_USER_BANK_ACOUNT_START,
  POST_USER_BANK_ACOUNT_COMPLETE,
  POST_USER_BANK_ACOUNT_ERROR,
  RESET_STATE,
} from 'redux/actionTypes'

export const postBankAccountAction = (userId, bankAccountData) => async (dispatch) => {
  dispatch({ type: POST_USER_BANK_ACOUNT_START })
  const token = localStorage.getItem('jwt')
  try {
    const req = await axios.post(`${BASE_URL_API}/api/users/${userId}/bank-account`, bankAccountData, {
      headers: {
        Authorization: token,
      },
    })
    dispatch({ type: POST_USER_BANK_ACOUNT_COMPLETE, payload: req.data.bank_account })
  } catch (error) {
    dispatch({ type: POST_USER_BANK_ACOUNT_ERROR, payload: error?.data?.message ?? 'Error al guardar la información' })
  }
}

export const getBankAccountAction = (userId) => async (dispatch) => {
  dispatch({ type: GET_USER_BANK_ACOUNT_START })
  const token = localStorage.getItem('jwt')
  try {
    const req = await axios.get(`${BASE_URL_API}/api/users/${userId}/bank-account`, {
      headers: {
        // Momentos esta api arrojó 403, ' NO ESTAS AUTORIZADO
        // con el token, arroja 404 'ERROR AL OBTENER CUENTA'
        Authorization: token,
      },
    })
    dispatch({
      type: GET_USER_BANK_ACOUNT_COMPLETE,
      payload: req.data.bank_account,
    })
  } catch (error) {
    dispatch({
      // Deberíamos dejar los errores en los reducers ?
      type: GET_USER_BANK_ACOUNT_ERROR,
      payload: error?.response?.message ?? 'Error al obtener la información bancaria',
    })
  }
}

export const resetBankAccountState = () => (dispatch) => {
  dispatch({ type: RESET_STATE })
}
