import React, { useRef, useLayoutEffect } from 'react'
import { NavTitle, NavHeader, OptionsUser } from './styles'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BurgerMenu from 'components/BurgerMenu'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const Nav = ({ title, isFixed = false, isLogin, isSingUp, isAdmin }) => {

  const burgerRef = useRef(null);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useLayoutEffect(() => {
    if (isDesktop && burgerRef.current) {
      const { toggleBurgerMenu, setClickOutsideEnabled } = burgerRef.current || {}
      toggleBurgerMenu && toggleBurgerMenu(true);
      setClickOutsideEnabled && setClickOutsideEnabled(false);
    }
  }, [isDesktop])

  return (
    <NavHeader isFixed={isFixed} isAdmin={isAdmin}>
      <Link to="/">
        <ArrowBackIosIcon style={{ color: 'white' }} />
      </Link>
      <NavTitle variant="button">{title}</NavTitle>

      {((isSingUp || isLogin) && <div></div>) || (
        <BurgerMenu ref={burgerRef}>
          <OptionsUser isFixed={isFixed} />
        </BurgerMenu>
      )}
    </NavHeader>
  )
}

export default Nav
