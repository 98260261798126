import React from 'react'
import {
  MiddleBannerBg,
  MiddleBannerPerson,
  CalendarIcon,
  MiddleContainerCalendar,
  MiddleTextContainer,
  GridContainer,
  GridMiddleHero,
} from './styles'
import { Typography } from '@material-ui/core'

const MiddleBanner = (props) => {
  return (
    <MiddleBannerBg>
      <GridContainer container>
        <MiddleContainerCalendar
          item
          sm={6}
          style={{ display: 'flex', height: '100%' }}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <MiddleTextContainer>
            <CalendarIcon />
            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, textAlign: 'center' }}>
              DEVOLVÉ EL PRÉSTAMO <div>SIN COMPLICACIONES</div>
            </Typography>
            <Typography variant="body1" gutterBottom>
              En la fecha seleccionada, se debita automáticamente de la misma cuenta a la que te transferimos el
              préstamo.
            </Typography>
          </MiddleTextContainer>
        </MiddleContainerCalendar>
        <GridMiddleHero style={{ height: '100%' }} direction="column" item sm={6}>
          <MiddleBannerPerson />
        </GridMiddleHero>
      </GridContainer>
    </MiddleBannerBg>
  )
}

export default MiddleBanner
