import React from 'react'
import { BackgroundHero, HeroText, Bold } from './styles'

const HomeHero = (props) => {
  return (
    <BackgroundHero>
      <HeroText color="primary" variant="h4" gutterBottom>
        TU PRÉSTAMO <Bold>FÁCIL Y RÁPIDO.</Bold>
      </HeroText>
    </BackgroundHero>
  )
}

export default HomeHero
