import styled from 'styled-components'
import StickyNav from 'components/StickyNav'
import { Typography } from '@material-ui/core'
import MenuDrawerUser from 'components/MenuDrawerUser'

export const NavHeader = styled(StickyNav)`
  & > :nth-child(3) {
    display: block;
    width: auto;
  }
`

export const OptionsUser = styled(MenuDrawerUser)`
  ${({ isFixed }) => isFixed && 'top: 60px'}
`

export const NavTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`
