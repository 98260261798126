import React from 'react'
import { HeaderContainer, HeaderLogo, Logo } from './styles'
import Menu from 'components/Menu'
import LoanLogo from 'assets/img/loanlogo.svg'

const Header = ({ isLogged }) => {
  return (
    <HeaderContainer isLogged={isLogged}>
      <Logo to="/">
        <HeaderLogo background={LoanLogo} />
      </Logo>
      <Menu isLogged={isLogged} />
    </HeaderContainer>
  )
}

export default Header
