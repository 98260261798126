import axios from 'axios'
import { GET_SCORING_START, GET_SCORING_COMPLETE, GET_SCORING_ERROR, BASE_URL_API } from 'redux/actionTypes'

export const getScoring = (level) => async (dispatch) => {
  dispatch({ type: GET_SCORING_START })

  try {
    const { data } = await axios.get(`${BASE_URL_API}/api/info/score-tna/${level || '999'}`)
    dispatch({ type: GET_SCORING_COMPLETE, payload: data })
  } catch (error) {
    dispatch({ type: GET_SCORING_ERROR, payload: error.status })
  }
}
