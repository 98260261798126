import React, { forwardRef, useEffect } from 'react'
import { Link, LoggedContainer } from './styles'
import { actionSignOut } from '../../redux/actions/auth/signOut.js'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Action } from 'styles'
import { UnloggedContainer } from './styles'
import { useHistory } from 'react-router-dom'

/**
 *  TODO: refactororizar todo el navbar.
 *  No hay diseño sólido.
 *  No sabemos las necesidades del cliente.
 */

export const UnloggedLinks = forwardRef((ref) => {
  const history = useHistory()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (isMobile && ref?.open) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'auto')
  }, [isMobile, ref.open])
  return (
    <UnloggedContainer>
      <Action {...(!isMobile && { variant: 'contained', color: 'primary' })}
        onClick={() => history.push('/ingresar')}>
        INGRESAR
      </Action>
      <Action
        {...(!isMobile && { variant: 'contained', color: 'secondary' })}
        onClick={() => history.push('/registrarse')}
      >
        CREAR CUENTA
      </Action>
    </UnloggedContainer>
  )
})

export const LoggedLinks = forwardRef((ref) => {
  const dispatch = useDispatch()
  const logOut = () => dispatch(actionSignOut())

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (isMobile && ref?.open) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'auto')
  }, [isMobile, ref.open])

  return (
    <LoggedContainer>
      <Link to="/user" bondFromHeader>
        <Action color="primary">Mis prestamos</Action>
      </Link>
      <Link to="/user/estado-de-cuenta" bondFromHeader>
        <Action color="primary">Estado de cuenta</Action>
      </Link>
      <Link to="/user/cbu-info" bondFromHeader>
        <Action color="primary">CBU INFO</Action>
      </Link>
      <Link to="/user/perfil" bondFromHeader>
        <Action color="primary">Mi Perfil</Action>
      </Link>
      <Link to="/" onClick={() => logOut()} bondFromHeader>
        <Action color="primary">Logout</Action>
      </Link>
    </LoggedContainer>
  )
})
