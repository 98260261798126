import axios from 'axios'
import {
  BASE_URL_API,
  VALIDATE_ACCOUNT_START,
  VALIDATE_ACCOUNT_COMPLETE,
  VALIDATE_ACCOUNT_ERROR,
} from 'redux/actionTypes'

export const actionVerifyAccount = (token) => async (dispatch) => {
  dispatch({ type: VALIDATE_ACCOUNT_START })
  try {
    const response = await axios.post(BASE_URL_API + '/api/auth/confirm-account', { token })
    dispatch({ type: VALIDATE_ACCOUNT_COMPLETE, payload: response.data })
  } catch (error) {
    dispatch({ type: VALIDATE_ACCOUNT_ERROR, payload: error.response.data })
  }
}
