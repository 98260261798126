import styled from 'styled-components'
import { Typography, Container, Button } from '@material-ui/core'
import Nav from './../../components/Nav'

export const NavMyAccount = styled(Nav)`
height:65px;
`
export const MyAccountContainer = styled.div`
background: #fff;
height: 100vh;
`

export const NavTitle = styled(Typography)`
  margin-left: 30px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`
export const FooterContainer = styled(Container)`
  background: #e6e6e6;
  height: 150px;
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  height: 20%;
`

export const SupportButton = styled(Button)`
  height: 35px;
  background: #808080;
  color: #fff;
`
