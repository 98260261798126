import { POST_RESET_PASSWORD_COMPLETE, POST_RESET_PASSWORD_ERROR, POST_RESET_PASSWORD_START, RESET_STATE } from 'redux/actionTypes'

const defaultState = {
  isSuccess: false,
  isLoading: false,
  error: false,
  message: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case POST_RESET_PASSWORD_START:
      return { ...state, isLoading: true }
    case POST_RESET_PASSWORD_COMPLETE:
      return { ...state, isLoading: false, isSuccess: true, message: action.payload }
    case POST_RESET_PASSWORD_ERROR:
      return { ...state, isLoading: false, error: true, message: action.payload }
    case RESET_STATE: return defaultState;
    default:
      return state
  }
}
