import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField } from '@material-ui/core'
import Spinner from 'components/Spinner'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getBankAccountAction, postBankAccountAction, resetBankAccountState } from 'redux/actions/bankAccount'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'
import styled, { css } from 'styled-components'
import { mainTheme } from 'theme/theme'
import * as yup from 'yup'
import { useNotification } from 'hooks/useNotification'

const schema = yup.object().shape({
  cbu: yup.string().max(22, 'El CBU tiene un máximo de 22 números').required('El CBU es obligatorio'),
  alias: yup.string().required('El ALIAS es obligatorio'),
})

const CbuAndAlias = () => {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const dispatch = useDispatch()
  const { showNotification } = useNotification()
  const user = useSelector(({ auth: { user } }) => user)
  const { data: bankAccount, isLoading, hasError, message } = useSelector(({ bankAccount }) => bankAccount)

  const [fields, setFields] = useState({
    cbu: '',
    alias: '',
  })

  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  useEffect(() => {
    dispatch(getBankAccountAction(user.id))
  }, [dispatch, user.id])

  const onFieldChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    if (name === 'cbu' && !/^\d+$/.test(value)) {
      if (value !== '') return
      setFields({ ...fields, cbu: fields.cbu.replace(value, '') })
    }
    setFields({ ...fields, [name]: value })
  }

  const onSubmit = (_, e) => {
    e.preventDefault()
    const dataToSend = { ...fields, userId: user.id }
    console.log(dataToSend)
    dispatch(postBankAccountAction(user.id, dataToSend))
  }

  useEffect(() => {
    if (hasError) {
      showNotification(message, 'error')
      dispatch(resetBankAccountState())
    }
  }, [dispatch, hasError, message, showNotification])

  const handleKeyPress = (e) => {
    if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault()
    }
  }

  return (
    <Container>
      {hasError && (
        <>
          <PageTitle>Error al validar CBU y Alias</PageTitle>
          <Message>Ups! hubo un error intentenlo de nuevo</Message>
        </>
      )}
      {bankAccount?.cbu || bankAccount?.alias ? (
        <>
          <PageTitle>CBU y Alias Validado</PageTitle>
          <Message>Ya tienes tu CBU validado</Message>
        </>
      ) : (
        <>
          <PageTitle>CBU y Alias</PageTitle>
          <Message>Agregá tu CBU para que podamos depositar el dinero</Message>
          <Form style={{ gap: '15px' }} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="standard-required"
              label="CBU"
              fullWidth
              name="cbu"
              inputRef={register}
              onKeyPress={handleKeyPress}
              onChange={onFieldChange}
            />
            {errors?.cbu && <label style={{ color: 'red' }}>{errors.cbu.message}</label>}
            <TextField
              id="standard-required"
              label="Alias"
              fullWidth
              name="alias"
              onChange={onFieldChange}
              inputRef={register}
            />
            {errors?.alias && <label style={{ color: 'red' }}>{errors.alias.message}</label>}
            <Button
              type="submit"
              style={{ width: '150px', margin: '0 auto' }}
              variant="contained"
              disabled={Object.values(errors).length > 0}
              color="primary"
            >
              Guardar
            </Button>
          </Form>
        </>
      )}
      <Spinner showSpinner={isLoading} />
    </Container>
  )
}

export default CbuAndAlias

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 11% 15%;
`

const PageTitle = styled.h2`
  font-size: 30px;
  line-height: 1;
  margin: 0;
  padding: 0;
  color: #29235c;
  font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
`
const Message = styled.p`
  font-size: 16px;
  color: grey;
`
const Form = styled.form`
  padding-top: 20px;
  display: grid;
  gap: 40px;
`
export const ErrorMessage = styled.p`
  color: #ffffff;
  font-family: ${mainTheme.typography.fontFamily};
  font-size: 16px;
  text-align: center;
`

export const SnackbarContainer = css`
  width: auto;
  padding: 0px 10px;
  background-color: #d32f2f;
  border-radius: 5px;
`
