import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, MenuItem, Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Nav from 'components/Nav'
import RegisterFooter from 'components/RegisterFooter'
import { useNotification } from 'hooks/useNotification'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'
import { actionSignUp, resetSignUpState } from 'redux/actions/auth/signUp'
import * as yup from 'yup'
import { codigosArea, listadoBancos } from './bancos'
import {
  Column,
  Container,
  CustomFormControl,
  CustomInputLabel,
  CustomTextField,
  ErrorMessage,
  FieldsContainer,
  Form,
  SubmitButton,
  SubTitle,
} from './styles'

const MIN_LENGHT_MSG = 'Mínimo 8 caractéres'
const NAMES_REGEX = /^[a-zA-Z\s]*$/
const DNI_REGEX = /^\d{8}(?:[-\s]\d{4})?$/g

const SignUp = ({ history }) => {
  const queryDataInitialState = {
    email: '',
    password: '',
    passwordConfirm: '',
    first_name: '',
    last_name: '',
    dni: '',
    sex: '',
    salary: '',
    bank: '',
    telephone: '',
    provincia: '',
  }

  const errorSchema = yup.object().shape({
    email: yup.string().email('El formato del email es inválido').required('El email es obligatorio'),
    password: yup.string().min(8, MIN_LENGHT_MSG).required('La contraseña es obligatoria'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
    first_name: yup.string().required('El nombre es obligatorio').matches(NAMES_REGEX, 'Solo espacios y letras.'),
    last_name: yup.string().required('El apellido es obligatorio').matches(NAMES_REGEX, 'Solo espacios y letras.'),
    dni: yup.string().matches(DNI_REGEX, 'El DNI tiene un formato inválido').required('El dni es obligatorio'),
  })

  const { register, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(errorSchema),
    mode: 'onChange',
  })

  const [queryData, setQueryData] = useState(queryDataInitialState)
  const [codArea, setCodeArea] = useState('')
  const { isError, isLoading, isSuccess, message: signUpMessage } = useSelector(({ signUp }) => signUp)
  const { showNotification } = useNotification()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyAuthByToken())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (e) => {
    setQueryData({
      ...queryData,
      [e.target.name]: e.target.value,
    })
  }

  const thereAreEmptyFields = Object.values(queryData).some((field) => field === null || field === '')

  const hasFieldsErrors = Object.values(errors).length > 0

  const isDisable = thereAreEmptyFields || hasFieldsErrors

  const onSubmit = (_, event) => {
    if (thereAreEmptyFields || hasFieldsErrors) return
    event.preventDefault()
    dispatch(actionSignUp(queryData))
  }

  // CAMBIAR RANGO DE INGRESOS
  const add = (n) => n * 100000
  const amounts = Array.from(Array(16).keys()).map((value) => add(value))

  useEffect(() => {
    if (isSuccess && !isDisable) {
      history.push('/ingresar')
      dispatch(resetSignUpState())
      setQueryData(queryDataInitialState)
      clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      showNotification(signUpMessage, 'error')
    }
  }, [isError, showNotification, signUpMessage])

  useEffect(() => {
    if (isSuccess) {
      showNotification(signUpMessage, 'success')
    }
  }, [isSuccess, showNotification, signUpMessage])

  const banks = Object.values(listadoBancos.banks).map((val) => {
    return { bank: listadoBancos.banks[val] }
  })

  const banksFilterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreAccents: true,
    stringify: (option) => option.bank,
  })

  const onBankChange = (e, newValue) => {
    const bank = newValue?.bank || ''
    setQueryData({ ...queryData, bank })
  }

  return (
    <Container>
      <Nav title="CREAR CUENTA" isFixed={true} isSingUp={true} />
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Column>
          <FieldsContainer>
            <SubTitle variant="button" display="block" gutterBottom>
              DATOS PERSONALES
            </SubTitle>
            <CustomTextField
              id="input-name"
              label="Nombre(s)"
              type="text"
              name="first_name"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.first_name && <ErrorMessage>{errors.first_name.message}</ErrorMessage>}
            <CustomTextField
              id="standard-basic"
              label="Apellido(s)"
              type="text"
              name="last_name"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.last_name && <ErrorMessage>{errors.last_name.message}</ErrorMessage>}
            <CustomTextField
              id="standard-basic"
              label="Numero de DNI"
              type="text"
              name="dni"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.dni && <ErrorMessage>{errors.dni.message}</ErrorMessage>}
            <CustomFormControl>
              <div style={{ display: 'flex' }}>
                <CustomTextField
                  name="provincia"
                  onChange={(e) => {
                    setCodeArea(codigosArea[e.target.value])
                    handleInputChange(e)
                  }}
                  value={queryData.provincia}
                  label="Codigo de Area"
                  inputRef={register}
                  style={{ minWidth: '150px', paddingRight: '10px' }}
                  select
                >
                  {Object.keys(codigosArea).map((value) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </CustomTextField>
                <CustomTextField
                  id="standard-basic"
                  label="Número de celular"
                  placeholder={!codArea ? 'ej: +1169558847' : ''}
                  type="number"
                  name="numeroCelular"
                  inputRef={register}
                  onChange={(e) => setQueryData({ ...queryData, telephone: codArea.concat(` - ${e.target.value}`) })}
                  {...(codArea !== '' && {
                    InputProps: {
                      startAdornment: (
                        <span style={{ height: 'auto', color: '#9e9e9e', marginRight: '5px' }}>+{codArea}</span>
                      ),
                    },
                  })}
                />
              </div>
            </CustomFormControl>
            <CustomFormControl>
              <CustomInputLabel id="demo-simple-select-label">Sexo</CustomInputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="sex"
                onChange={(e) => handleInputChange(e)}
                inputRef={register}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Femenino</MenuItem>
              </Select>
              {errors?.sex && <ErrorMessage>{errors.sex.message}</ErrorMessage>}
            </CustomFormControl>

            <CustomFormControl>
              <CustomInputLabel id="demo-simple-select-label-salary">Rango de ingresos</CustomInputLabel>
              <Select
                labelId="demo-simple-select-label-salary"
                id="demo-simple-select"
                name="salary"
                onChange={(e) => handleInputChange(e)}
                inputRef={register}
              >
                {amounts.map((amount, i) => {
                  return i !== 0 && <MenuItem value={String(amount)}>$ {amount.toString()}</MenuItem>
                })}
              </Select>
              {errors?.salary && <ErrorMessage>{errors.salary.message}</ErrorMessage>}
            </CustomFormControl>
            <CustomFormControl>
              <Autocomplete
                inputRef={register}
                options={banks}
                onChange={onBankChange}
                getOptionLabel={(option) => option.bank}
                filterOptions={banksFilterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: { color: '#9e9e9e' },
                    }}
                    label="Banco donde recibís tus ingresos"
                    margin="normal"
                  />
                )}
              />
              {errors?.bank && <ErrorMessage>{errors.bank.message}</ErrorMessage>}
            </CustomFormControl>
          </FieldsContainer>
        </Column>

        <Column>
          <FieldsContainer>
            <SubTitle variant="button" display="block" gutterBottom>
              DATOS DE USUARIO
            </SubTitle>
            <CustomTextField
              id="standard-basic"
              type="text"
              name="email"
              label="E-mail"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
            <CustomTextField
              id="standard-basic"
              type="password"
              name="password"
              label="Contraseña"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
            <CustomTextField
              id="standard-basic"
              type="password"
              name="passwordConfirm"
              label="Confirmar"
              inputRef={register}
              onChange={(e) => handleInputChange(e)}
            />
            {errors?.passwordConfirm && <ErrorMessage>{errors.passwordConfirm.message}</ErrorMessage>}
            {isLoading ? (
              <CircularProgress size={100} color="primary" />
            ) : (
              <SubmitButton disabled={isDisable} type="submit" variant="contained" color="primary">
                Crear Cuenta
              </SubmitButton>
            )}
          </FieldsContainer>
        </Column>
      </Form>
      <RegisterFooter signUp={true} />
    </Container>
  )
}

export default SignUp
