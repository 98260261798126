import React, { useRef, useEffect } from 'react'
import BurgerMenu from 'components/BurgerMenu'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { UnloggedLinks, LoggedLinks } from './Items'

const Menu = ({ isLogged }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))


  const LoggedMenu = () => (
    <>
      {isMobile ? (
        <BurgerMenu>
          <LoggedLinks />
        </BurgerMenu>
      ) : (
        <LoggedLinks />
      )}
    </>
  )

  const UnloggedMenu = () => (
    <>
      {isMobile ? (
        <BurgerMenu isHome>
          <UnloggedLinks />
        </BurgerMenu>
      ) : (
        <UnloggedLinks />
      )}
    </>
  )

  return <>{isLogged ? <LoggedMenu /> : <UnloggedMenu />}</>
}

export default Menu
