import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Modal from 'components/Modal'

export const LoanAction = styled(Button)`
  z-index: 3;
  margin: 0 20px;
  ${({ redirectedFromUser }) =>
    redirectedFromUser &&
    css`
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
      animation: pulse-white .9s infinite;
      @keyframes pulse-white {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    `}
`

export const LoanCalculatorContainer = styled.div`
  margin: 2rem 0;
  /* width: 35vw; */
  display: flex;
  flex-flow: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const SummaryContainer = styled.div`
  padding: 5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  width: 30vw;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`
export const SummaryItem = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid white;
`
export const SummaryItemText = styled(Typography)``

export const ModalWrapper = styled(Modal)`
  .MuiDialog-paperWidthSm {
    max-width: 1000px !important;
    max-height: 600px !important;
  }
`
