import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

export const SummaryItem = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #000;

  ${(props) =>
    props.isDefaulterCustomer &&
    css`
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      & > p {
        color: red;
      }
    `}
  ${(props) =>
    props.isFinished &&
    css`
      & > div > span,
      p {
        color: yellow;
      }
    `}
`
export const SummaryItemText = styled(Typography)`
  color: #1a1a1a;
  font-weight: 600;
`
