import axios from 'axios'
import {
  RESET_STATE,
  RESET_PASSWORD_COMPLETE,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  BASE_URL_API,
} from 'redux/actionTypes'

export const newPassword = (password, token) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_START })

  try {
    const response = await axios.post(BASE_URL_API + '/api/auth/change-password', { token, password })
    dispatch({ type: RESET_PASSWORD_COMPLETE, isSuccess: 'La contraseña se cambio correctamente' })
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_ERROR, payload: 'hubo un error al intentar cambiar la contraseña' })
  }
}

export const resetNewPwdState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  })
}
