import React, { Component } from "react";
import { ErrorComponent } from "./ErrorComponent";

export class ErrorBoundary extends Component {

    state = {
        hasError: false
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            message: error.message
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log("error: ", error);
        console.log("info: ", info);
    }

    handleRestart = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent reset={this.handleRestart} />;
        }
        return this.props.children;
    }
};