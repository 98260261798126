/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { MainContent } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from 'components/Spinner'

//components intern
import HomeHero from './components/HomeHero'
import Validate from './components/Validate'

//components reusables
import MainContentHeader from 'components/MainContainerHeader'
import Header from 'components/Header'
import Footer from 'components/Footer'
import LoanCalculator from 'components/LoanCalculator'

import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'

const HomeUser = (props) => {
  const dispatch = useDispatch()
  const [is_validated, setValidated] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const authData = useSelector((state) => state.auth)

  const { user, isAuthenticated } = authData || {}

  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [dispatch])

  useEffect(() => {
    if (user && [user?.is_validated, user?.is_validated_dni, user?.is_validated_cbu].includes(false)) setValidated(false)
    if (user && typeof user?.is_validated == 'boolean') setLoading(false)
  }, [user])
  return (
    <>
      <Header isLogged={true} />
      <Spinner showSpinner={isLoading} />
      {!is_validated && <Validate isHomeUser={true} />}
      <HomeHero isHomeUser={true} nameUser={user && user?.first_name} />
      <MainContent>
        <MainContentHeader />
        <LoanCalculator isIndex={false} history={props.history} />
      </MainContent>
      <Footer isAuthenticated={isAuthenticated} />
    </>
  )
}

export default HomeUser
