import styled from 'styled-components'
import { Bond, Logo } from 'styles'
import { List, ListItem } from '@material-ui/core'

export const DMContainer = styled.div`
  display: ${(props) => props.principal || (props.head && 'flex')};
  flex-direction: ${({ principal }) => principal && 'column'};
  justify-content: ${({ head }) => head && 'space-between'};
  margin-top: ${({ links }) => links && '70px'};
  position: absolute;
  z-index: 5;
  border-radius: 5px;
  padding: ${({ head }) => (head ? '1.1rem 3rem' : '0 20px')};
  color: #203855;
  right: 0;
  left: 0;
  top: 0;
  height: ${({ head }) => (head ? '65px' : 'inherit')};
`
export const UnloggedContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;

  @media (max-width: 960px) {
    left: 0;
    top: 65px;
    background: #29235c;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 0 15px;
    justify-content: flex-start;
    & > button {
      background-color: transparent;
      color: #fff;
      padding: 14px;
      font-size: 17px;
      border-bottom: 1px solid;
      border-radius: 0;
    }
  }
`
export const HeaderLogged = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
  position: absolute;
  z-index: 5;
  color: #203855;
  right: 0;
  left: 0;
  top: 0;
  height: 65px;
  & > a {
    height: 100%;
    svg {
      height: 100%;
    }
  }
  & > svg {
    height: 100%;
    position: absolute;
    right: 25px;
    top: 15px;
    width: 50px;
    height: 40px;
    background: #1ec0ca;
    margin-right: 10px;
    @media (max-width: 980px) {
      width: 25px;
      height: 30px;
    }
  }
`

export const HeaderLoggedContainer = styled.div`
  display: none;
  & > svg.close {
    width: 35px !important;
    height: 35px !important;
    margin-top: 10px;
  }
  @media (max-width: 600px) {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 20px 10px;
  }
`

export const LoggedContainer = styled.div`
  display: flex;
  justify-content: right;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 5;
  position: fixed;
  & > a {
    margin: 15px 0;
    & > button {
      font-size: 18px;
    }
  }

  @media (max-width: 980px) {
    left: 0;
    top: 65px;
    background: #29235c;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 0 15px;
    & > a {
      display: block;
      border-bottom: 1px solid;
      text-align: center;
      color: #fff;
      & > button {
        color: #fff;
      }
    }
    & > a:last-child {
      margin-top: auto;
      margin-bottom: 70px;
    }
  }
`

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  background: #fff;
  height: ${({ mobileStyles }) => (mobileStyles ? '100vh' : '64px')};
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    background-color: #e9edf2;
    height: 80px;
  }
`
export const MobileLogo = styled(Logo)`
  background: none;
  width: 25px;
  height: 25px;
`

export const ListLinks = styled(List)`
  padding: 1.1rem 2rem;
  font-family: ${({ theme }) => theme.typography.caption};
  font-size: 21px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
export const Link = styled(Bond)`
  display: ${({ mobileStyles }) => mobileStyles && 'block !important'};
`
export const Item = styled(ListItem)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
