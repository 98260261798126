import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import HeroBgSvg from 'assets/home/person-hero-home-full.svg'

export const BackgroundHero = styled.div`
  width: 100%;
  background-image: url(${HeroBgSvg});
  /* background-size: 180% 100%; */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  background-position-x: 75%;
  background-position-y: 10px;
  height: 600px;

  @media (max-width: 540px) {
    /* height: 15%; */
    background-size: 259% 80%;
    background-position-x: 72%;
    background-position-y: bottom;
  }
  @media (max-width: 990px) {
    height: 339px;
  }
`

export const HeroText = styled(Typography)`
  font-weight: 400;
  position: relative;
  left: 17%;
  top: 20%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    position: absolute;
    left: 5%;
    max-height: 90px;
    margin: 0;
    padding: 20px 10px;
    font-size: 16px;
    top: 15%;
  }
`
export const Bold = styled.div`
  font-weight: 800;
`
