import React, { useEffect } from 'react'
import { MainContent } from './styles'
import { verifyAuthByToken } from 'redux/actions/auth/signIn.js'
import { useDispatch } from 'react-redux'

//components intern
import Faqs from './components/Faqs'
import HomeHero from './components/HomeHero'
import SocialBanner from './components/SocialBanner'
import MiddleBanner from './components/MiddleBanner'

//components reusables
import MainContentHeader from 'components/MainContainerHeader'
import Header from 'components/Header'
import Footer from 'components/Footer'
import LoanCalculator from 'components/LoanCalculator'

const Home = ({ history }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(verifyAuthByToken())
  }, [])

  return (
    <>
      <Header />
      <HomeHero />
      <MainContent >
        <MainContentHeader />
        <LoanCalculator isIndex={true} history={history} userNotLoggedIn />
      </MainContent>
      <MiddleBanner />
      <SocialBanner />
      <Faqs />
      <Footer />
    </>
  )
}

export default Home
