import styled, { css } from 'styled-components'
import { Typography, Grid } from '@material-ui/core'
import HeroBgSvg from 'assets/home/person-hero-home-full.svg'
import { makeStyles } from '@material-ui/core/styles'

export const BackgroundHero = styled.div`
  transform: scaleX(-1);
  width: 100%;
  background: ${({ isHomeUser }) => isHomeUser && '#1EC0CA'};
  background-image: url(${HeroBgSvg});
  background-position-x: 150%;
  background-position-y: 45px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  /* background-position-x: 75%;
  background-position-y: 10px; */
  height: 600px;
  @media (max-width: 540px) {
    /* height: 15%; */
    background-size: 259% 80%;
    background-position-x: 72%;
    background-position-y: bottom;
  }
  @media (max-width: 990px) {
    height: 339px;
  }
`

export const AvailableBox = styled(Grid)`
  transform: scaleX(-1);
  margin: 5% 15%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  border-radius: 25px;
  height: ${({ principal }) => principal && '150px'};
`
export const LastLoanBox = styled(AvailableBox)`
  background: #bababa91;
  color: #fff;
`

export const HeroContainer = styled.div`
  /* transform: scaleX(-1); */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transform: scaleX(-1);
  background: ${({ isHomeUser }) => isHomeUser && '#1EC0CA'};
`
export const ComboBox = styled.div`
  transform: scaleX(-1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
export const TextContainer = styled.div`
  padding-left: ${({ secondaryText }) => secondaryText && '45px'};
  h4 {
    margin-bottom: 0;
    padding-right: ${(props) => props.dni === true && '60px'};
    font-size: 3rem;
  }
  button {
    width: 45%;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      width: 90%;
    }
  }
  ${({ greeting }) =>
    greeting &&
    css`
      width: 25%;
      position: absolute;
      right: 25%;
      top: 65%;
      color: #fff;
    `};
`

export const HeroText = styled(Typography)`
  font-weight: 400;
  position: relative;
  left: 17%;
  top: 20%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    position: absolute;
    left: 5%;
    max-height: 90px;
    margin: 0;
    padding: 20px 10px;
    font-size: 16px;
    top: 15%;
  }
`
export const Bold = styled.div`
  font-weight: 800;
`
export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
  },
}))
