import { GET_SIMULATE_LOAN_START, GET_SIMULATE_LOAN_COMPLETE, GET_SIMULATE_LOAN_ERROR } from 'redux/actionTypes'

const defaultState = {
  isLoading: false,
  hasError: false,
  feesList: [],
}

const simulateLoan = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_SIMULATE_LOAN_START:
      return { ...state, isLoading: true, hasError: false }
    case GET_SIMULATE_LOAN_COMPLETE:
      return { ...state, isLoading: false, feesList: payload.fees, hasError: false }
    case GET_SIMULATE_LOAN_ERROR:
      return { ...state, hasError: true }
    default:
      return state
  }
}


export default simulateLoan;
