import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import LoanDetails from 'components/LoanDetails'
import {
  SummaryContainer,
  SecondaryBox,
  SummaryItem,
  SummaryItemText,
  PrincipalBox,
  ButtonsContainer,
  ModalWrapper,
} from './styles'

import moment from 'moment'

const LoanItem = ({ loanData }) => {
  const { id, amount, createdAt, interest, loan_state_id, fees, amount_of_fees } = loanData

  const [openModal, setOpenModal] = useState(false)

  const [detailsData, setDetailsData] = useState(null)

  const handleMoreDetails = () => {
    toggleModal()
    setDetailsData({
      possible_loan_states: 'possible_loan_states',
      loanId: id,
      amount: amount,
      interest: interest,
      fees: fees,
    })
  }

  const toggleModal = () => setOpenModal(!openModal)

  const loanStatusMsg = (loan_state_id) => {
    switch (loan_state_id) {
      case 1:
        return 'PENDIENTE'
      case 2:
        return 'RECHAZADO'
      case 3:
        return 'APROBADO'
      default:
        return 'FINALIZADO'
    }
  }

  const totalAmount = parseFloat(amount + amount * interest).toFixed(2)
  return (
    <PrincipalBox>
      <SummaryContainer>
        <SummaryItem>
          <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
            FECHA DE SOLICITUD
          </SummaryItemText>
          <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
            {moment(createdAt).format('DD-MM-YYYY')}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            ESTADO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {loanStatusMsg(loan_state_id)}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            MONTO SOLICITADO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {`$ ${amount}`}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            CANTIDAD DE CUOTAS
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {amount_of_fees}
          </SummaryItemText>
        </SummaryItem>
      </SummaryContainer>
      <SecondaryBox>
        <ButtonsContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMoreDetails}
            disabled={loan_state_id == 1 || loan_state_id == 2}
          >
            Ver detalles{(loan_state_id == 1 || loan_state_id == 2) && '-No habilitado aun...'}
          </Button>
          <ModalWrapper
            onClose={toggleModal}
            open={openModal}
            modalTitle={'DETALLES DE TU PRÉSTAMO'}
            titleButtonAcept={'Aceptar'}
            titleButtonCancel={'Cancelar'}
            handleButtonAcept={toggleModal}
            handleButtonCancel={toggleModal}
          >
            <LoanDetails tails detailsData={detailsData} />
          </ModalWrapper>
        </ButtonsContainer>
      </SecondaryBox>
    </PrincipalBox>
  )
}
export default LoanItem
