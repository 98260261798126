import { Button, FormControl, InputLabel, TextField, Typography } from '@material-ui/core'

import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
`
export const MsgError = styled(Typography)`
  margin: 0 auto;
  color: red;
  font-size: 15px;
  font-weight: 900;
  width: fit-content;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* min-height: 85vh; */
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    /* min-height: 100%; */
  }
`
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const CustomFormControl = styled(FormControl)`
  width: 80%;
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.grey['500']};
  }
`
export const StickyNav = styled.div`
  width: 99vw;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`

export const CustomTextField = styled(TextField)`
  margin: 10px 0;
  width: 80%;
  .MuiInput-input {
    color: ${({ theme }) => theme.palette.common.black};
  }
  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`

export const SubmitButton = styled(Button)`
  width: 80%;
  margin: 40px 0;
  font-size: 20px;
  font-weight: 900;
`

export const CustomInputLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.grey['500']};
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`

export const SubTitle = styled(Typography)`
  margin: 30px 0 15px 0;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1em;
  font-weight: 900;
`

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  height: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: column;
    align-items: center;
    height: 100% !important;
  }
`
export const ErrorMessage = styled.span`
margin: 0;
color: red;
align-self: self-start;
padding-left: 10%;
`;

export const Input = styled.input`
display: block;
box-sizing: border-box;
width: 100%;
border: none;
border-bottom: 1px solid #9e9e9e;
padding: 10px 0px;
margin-bottom: 10px;
font-size: 14px;
width: 80%;
&:focus {
  outline: none;
}

`;

export const Label = styled.span`
width: 80%;
font-weight: 400;
color: #9e9e9e;
`;

