import Resizer from "react-image-file-resizer";

const defaultConfig = {
    maxWidth: 400,
    maxHeight: 250,
    compressFormat: "JPEG",
    quality: 100,
    rotation: 0,
    outputType: "file",
    responseUriFunc: (uri) => console.log('img uri default log', uri)
};

const resizer = (file) => {

    const {
        maxWidth,
        maxHeight,
        compressFormat,
        quality,
        rotation,
        outputType } = defaultConfig

    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            rotation,
            (uri) => {
                resolve(uri)
            },
            outputType,
        )
    })
};

export default resizer;