import React, { useState } from 'react'
import { FaqsContainer, QuestionsIcon, FaqContainer, ExpandBtn, ExpandPanel } from './styles'
import { Typography } from '@material-ui/core'
import { Icon } from 'components/Icon/Icon'
import { faqsMap } from './util'

const Faqs = () => {
  const [expand, setExpand] = useState({
    current: 0,
    show: true
  })
  
  const { current, show } = expand

  return (
    <FaqsContainer>
      <QuestionsIcon />
      <Typography variant="h6" gutterBottom style={{ fontWeight: 700, textAlign: 'center' }}>
        ¡NO TE QUEDES CON DUDAS!
      </Typography>
      <ExpandPanel>
        {faqsMap.map(({ title, id, body, flexDirection }) => {
          const shouldShow = current === id && show;
          return (
            <FaqContainer key={id}>
              <ExpandBtn role="button" onClick={() => setExpand({ current: id, show: !expand.show })}>
                {title}
                <Icon fill="#fff" height="17px" width="17px" name="arrow_down" className={shouldShow && 'toggle'} />
              </ExpandBtn>
              <div
                style={{
                  display: shouldShow ? 'block' : 'none',
                  padding: '1rem',
                  fontSize: 12,
                  flexDirection: flexDirection,
                }}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </FaqContainer>
          )
        })}
      </ExpandPanel>
    </FaqsContainer>
  )
}

export default Faqs
