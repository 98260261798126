import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const drawerWidth = 240

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

export const HeaderLogo = styled.div`
  background: url('${({ background }) => background}');
  background-repeat: no-repeat;
  height: 30px;
  background-position-x: 10px;
  margin-top: auto;
  margin-bottom: 70px;
  position: relative;
  & > span {
    color: #fff;
    font-size: 1rem;
    position: absolute;
    left: 30%;
    font-weight: 400;
    bottom: 0;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({ theme }) => theme.palette.primary.main};
  height: 100vh;
  /* top: 60px; */
  left: 0;
  z-index: 5;
  top: ${({ isFixed }) => (isFixed ? '60px' : '0')};
`
