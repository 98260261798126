import styled from 'styled-components'
import { ReactComponent as LoanLogoFooterSVG } from 'assets/home/loan-footer-logo.svg'
import { Button, Typography } from '@material-ui/core'

//Footer
export const FooterContainer = styled.div`
  background-color: #e6fdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* FIXME: this margin should not stay here. Boxes must be being aligned by theirself */
  /* margin-top: 300px; */
`

export const LogoLoanFooter = styled(LoanLogoFooterSVG)`
  height: 60px;
  margin: 3rem 0;
`

export const BtnsGrid = styled.div`
  border-bottom: 1px solid #808080;
  & > div:nth-child(1) {
    padding: 2rem 0;
    display: flex;
    & > * {
      margin-right: 1rem;
    }
    & :last-of-type {
      margin-right: 0;
    }
    & > button {
      border-radius: 35px;
    }
  }
  p {
    text-align: center;
    font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
    & > div:nth-child(1) {
      flex-direction: column;
      padding: 0 2rem;
      & > button {
        padding: 10px 0;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`
export const Btn = styled(Button)`
  width: 17vw;
  font-size: 11px;
  padding: 10px 0;
  color: white;
  background-color: ${(props) => (props.secondaryColor ? props.theme.palette.secondary.main : '#808080')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'lowercase')};
  &:hover {
    color: #000;
  }
`

export const LegalText = styled(Typography)`
  font-size: 11px;
  padding: 10px 0;
  padding: 2rem 10vw;
  text-align: center;
`
