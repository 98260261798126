import React from 'react'
import { useSelector } from 'react-redux'
import { BackgroundHero, LastLoanBox, AvailableBox, HeroContainer, TextContainer, ComboBox, useStyles } from './styles'
import { Typography } from '@material-ui/core'
import { getFormatedThousands } from 'helpers/getFormatedThousands'

const HomeHero = ({ isHomeUser, nameUser }) => {
  const classes = useStyles()
  return (
    <BackgroundHero isHomeUser={isHomeUser}>
      <HeroContainer>
        {/* <AvailableBox principal xs={12} sm={3} className={classes.paperSecondary}>
          <ComboBox>
            <TextContainer>
              <Typography variant="body1">TENÉS DISPONIBLE:</Typography>
              <Typography variant="body1">
                {(amount_last_loan == 0 && '$ 30.000') || 'YA TENES PRESTAMO VIGENTE'}
              </Typography>
            </TextContainer>
          </ComboBox>
        </AvailableBox>
        <LastLoanBox xs={12} sm={3} className={classes.paperSecondary}>
          <ComboBox>
            <TextContainer>
              <Typography variant="body1">VIGENTE:</Typography>
              <Typography variant="h4">$ {getFormatedThousands(amount_last_loan)}</Typography>
            </TextContainer>
          </ComboBox>
        </LastLoanBox> */}
        <TextContainer greeting={true}>
          <Typography variant="body1">HOLA,</Typography>
          <Typography variant="h4">{nameUser && nameUser.toUpperCase()}</Typography>
        </TextContainer>
      </HeroContainer>
    </BackgroundHero>
  )
}

export default HomeHero
