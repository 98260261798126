import styled from 'styled-components'

export const NavButton = styled.a`
  cursor: pointer;
  position: relative;
  path {
    fill: ${({ isHome, theme }) => (isHome && theme.palette.common.black) || theme.palette.common.white};
  }

`
export const Container = styled.div`
  padding: 20px;
  text-align: right;

  & > a > svg.close {
    width: 35px !important;
    height: 35px !important;
  }
`
