import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FooterContainer, LogoLoanFooter, Btn, BtnsGrid, LegalText } from './styles'
const Footer = ({ isAuthenticated }) => {

  const history = useHistory()

  const [infoText, setInfoText] = useState('')

  const handleFooterButtons = (redirect, info) => {
    if (redirect) history.push('/registrarse')
    setInfoText(info)
  }

  const footerBtns = [
    {
      ...(!isAuthenticated && {
        title: 'CREAR CUENTA',
        uppercase: true,
        secondaryColor: true,
        redirect: true,
        info: '',
      })
    },
    {
      title: 'Términos y condiciones',
      link: '',
      info: 'Terminos y condiciones >>>>',
    },
    {
      title: 'Políticas de privacidad',
      link: '',
      info: 'Politicas de privacidad',
    },
    {
      title: 'Soporte',
      link: '',
      info: 'Ante cualquier inconveniente contactate con soporte',
    },
    {
      title: 'Contacto',
      link: '',
      info: 'Podes encontrarnos en 0800-loan',
    },
  ]
  const legalText =
    'Tasa de Interés Nominal Anual (TNA) = 99% Tasa de Interés Efectiva Anual (TEA) = 159.03%. La tasa de interés es fija (valores sin iva). CFT(NA): 337.18%. El período máximo de repago son 180 días, todos tienen la opción de repago mínimo de 90 días.'
  return (
    <>
      <FooterContainer>
        <LogoLoanFooter />
        <BtnsGrid>
          <div>
            {footerBtns.filter((possibleEmptyObject => Object.keys(possibleEmptyObject).length > 0))
              .map(({ id, info, color, link, title, uppercase, redirect, secondaryColor }, index) => (
                <Btn
                  onClick={() => handleFooterButtons(redirect, info)}
                  uppercase={uppercase}
                  secondaryColor={secondaryColor}
                  uppercasevariant="contained"
                  key={index + title}
                >
                  {title}
                </Btn>
              ))}
          </div>
          <div>
            <p> {infoText} </p>
          </div>
        </BtnsGrid>
        <LegalText variant="body1">{legalText}</LegalText>
      </FooterContainer>
    </>
  )
}

export default Footer
