import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const SupportButton = styled(Button)`
  height: 35px;
  position: absolute;
  bottom: 40px;
`

export const FooterContainer = styled.div`
    background-image:url('${(props) => props.src}');
    background-size: cover;
    background-position-y: 0;
    background-position-x: center;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    justify-content: center;
    height: 40vh;
    & > button {
      position: relative;
      bottom: 0;
      width: 50vw;
      top: 70%;
    } 
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      height: ${(props) => (props.specialHeight === true ? '30%' : props.signUp === true ? '30%' : '20vh')};
      background-size: ${(props) => (props.specialHeight === true ? '100% 100%' : 'inherit')}
      & > button {
        width: 300px;
        top: ${(props) => (props.specialHeight === true ? '50%' : 'inherit')}

      }
    }
 `
