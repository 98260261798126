import { VALIDATE_ACCOUNT_START, VALIDATE_ACCOUNT_COMPLETE, VALIDATE_ACCOUNT_ERROR } from 'redux/actionTypes'

const defaultState = {
  isSuccess: false,
  isLoading: false,
  message:""
}

export default (state = defaultState, {type, payload}) => {
  switch (type) {
    case VALIDATE_ACCOUNT_START:
      return { ...state, isLoading: true }
    case VALIDATE_ACCOUNT_COMPLETE:
      return { ...state, isLoading: false, isSuccess: payload.message, message: payload.message }
    case VALIDATE_ACCOUNT_ERROR:
      console.log(payload)
      return { ...state, isLoading: false, isSuccess: false , message: payload.detailMessage}
    default:
      return state
  }
}
