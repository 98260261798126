import { RESET_STATE, SEARCH_SIGN_UP_COMPLETE, SEARCH_SIGN_UP_ERROR, SEARCH_SIGN_UP_START } from 'redux/actionTypes'

const defaultState = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  message: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_SIGN_UP_START:
      return { ...state, isLoading: true, message: '', isError: false, isSuccess: false }
    case SEARCH_SIGN_UP_COMPLETE:
      return { ...state, isLoading: false, message: action.message, isSuccess: action.isSuccess, isError: false }
    case SEARCH_SIGN_UP_ERROR:
      return { ...state, isLoading: false, message: action.message, isError: true }
    case RESET_STATE:
      return { ...defaultState }
    default:
      return state
  }
}
