import styled from 'styled-components'
import { Box } from '@material-ui/core'

export const BoxHeader = styled(Box)`
  h6,
  button {
    margin-top: 35px;
    height: auto !important;
  }
  margin-bottom: 3%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin-bottom: 5%;
  }
`
export const Container = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
`
export const LoansWrapper = styled.div`
height: ${({largeView}) => largeView ? 'calc(100vh + 160px)' : '100%'}
`