import styled from 'styled-components'
import { Typography, Button, TextField, Container } from '@material-ui/core'
import facebookSVG from 'assets/register/facebook-btn.svg'
import googleSVG from 'assets/register/google-btn.svg'
import { Link } from 'react-router-dom'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`

export const StickyNav = styled.div`
  width: 99vw;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`

export const CustomTextField = styled(TextField)`
  margin: 10px 0;
  width: 80%;
  .MuiInput-input {
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey['500']};
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`

export const SubTitle = styled(Typography)`
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.grey['500']};
  font-size: 10px;
  font-weight: 900;
  width: fit-content;
`
export const MsgError = styled(Typography)`
  margin: 0 auto;
  color: red;
  font-size: 15px;
  font-weight: 900;
  width: fit-content;
`
export const MsgSucces = styled(Typography)`
  margin: 0 auto;
  color: #3dc77f;
  font-size: 15px;
  font-weight: 900;
  width: fit-content;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`

export const SubmitButton = styled(Button)`
  width: 80%;
  margin: 20px 0;
`
export const Bond = styled(Link)`
  text-decoration: none;
`
export const Divider = styled.hr`
  width: 58%;
  margin-top: 40px;
`

export const SocialNetwork = styled(Typography)`
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 35px;
  padding: 0px 30px;
`

export const ContainerSocialNetwork = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
export const Google = styled.div`
  background-image: url(${googleSVG});
  background-size: contain;
  background-repeat: no-repeat;
  width: 148px;
  height: 32px;
  cursor: pointer;
  margin-right: 15px;
  :hover {
    opacity: 0.5;
  }
`
export const Facebook = styled.div`
  background-image: url(${facebookSVG});
  background-size: contain;
  background-repeat: no-repeat;
  width: 148px;
  height: 32px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`
export const Image = styled.div`
    position:absolute;
    bottom:50px;
    height: 300px;
    width:250px;
    background-image:url('${(props) => props.src}');
    background-repeat: no-repeat;
    background-size: 100%;
    z-index:1;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        bottom:100px;
        height: 300px;
        width:220px;
  }
`

export const BackgroundPerson = styled.div`
    background-image:url('${(props) => props.src}');
    background-repeat: no-repeat;
    height: 100%;
background-size: 57% 80%;
height: 100%; 
background-position-x: center;
background-position-y: bottom;
width: 100%;
position: relative;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
  }
`

export const MiddleContainer = styled(Container)`
  height: calc(100vh - 200px);
  background: #29235c 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  margin-top: 50px;
  padding: 0;
  max-width: 2000px;
`
export const Ad = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 30px;
`
export const Action = styled(Button)`
  font-size: 0.7rem;
  margin-top: 0.5rem;
`

export const FooterContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: center;
  height: 25%;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 50%;
  }
`
