import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Container, Button ,Paper} from '@material-ui/core'
import { Link } from 'react-router-dom'

export const StickyNav = styled.div`
  width: 99vw;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
`

export const NavTitle = styled(Typography)`
  margin-left: 30px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`
export const GridContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`

export const Wrapper = styled(Grid)`
  width: 70%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const InfoBoxesContainer = styled(Paper)`
  height: 166px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: auto;
  }
`

export const GeneralContent = styled.div`
  height: ${({ bottom }) => bottom && '200px'};
  padding: 25px;
  align-items: center;
  width: 70%;;
  margin: 0;
  ${({ fullWidth }) => fullWidth && 'background: #fff'}
  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
    `}
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      height: calc(100vh + 160px);
  }

`
export const FullSizeGrid = styled(Grid)`
  padding: 12px;
`

export const Action = styled(Button)`
  margin-left: 1rem;
  font-size: 0.7rem;
`

export const ActionButton = styled(Action)`
  /* font-size: 1.3rem; */
  width: 10vw;
`
export const FooterContainer = styled(Container)`
  background: #e6e6e6;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SupportButton = styled(Button)`
  height: 35px;
  /* position: absolute; */
  /* bottom: 40px; */
  background: #808080;
  width: 15vw;
  color: #fff;
`
export const Bond = styled(Link)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: ${({ bondFromHeader }) => bondFromHeader && 'none'};
  }
`

export const ComboBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
export const PictureContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 0 25px;
`

export const TextContainer = styled.div`
  padding-left: ${({ secondaryText }) => secondaryText && '45px'};
  h4 {
    margin-bottom: 0;
    padding-right: ${(props) => props.dni === true && '60px'};
  }
  button {
    width: 45%;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      width: 90%;
    }
  }
`

export const VerifyContainer = styled.div`
  padding-top: 40px;
`

export const AddPicButton = styled(Button)`
  height: 35px;
  background: #1ec0ca;
  color: #fff;
`

export const VerifyBall = styled.div`
background: url('${({ background }) => background}');
width: 40px;
height: 40px;
`
export const VerifyBallGray = styled.div`
  background: lightgray;
  width: 45px;
  height: 45px;
  fill: gray;
  border-radius: 25px;
`
export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
  },
}))
