import { GET_VAR_ECONOMIC_START, GET_VAR_ECONOMIC_COMPLETE, GET_VAR_ECONOMIC_ERROR } from 'redux/actionTypes'

const defaultState = {
  isSuccess: null,
  isLoading: false,
  varEconomic: {},
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_VAR_ECONOMIC_START:
      return { ...state, isLoading: true }
    case GET_VAR_ECONOMIC_COMPLETE:
      return { ...state, isLoading: false, isSuccess: payload.success, varEconomic: payload.data }
    case GET_VAR_ECONOMIC_ERROR:
      return { ...state, isLoading: false, isSuccess: false }
    default:
      return state
  }
}
