import styled from 'styled-components'
import { Container } from '@material-ui/core'
import { Icon } from 'components/Icon/Icon'
import { ReactComponent as AppLogo } from 'assets/img/loan-logo-light.svg'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const ActionsContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  & > a {
    margin-right: 10px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`
export const HeaderContainer = styled.div`
  background: ${({ isLogged }) => isLogged && '#1EC0CA'};
  height: 65px;
  display: grid;
  grid-template-columns: ${({ isLogged }) => (isLogged ? '30% 69%;' : '50% 49%;')};
  grid-template-rows: minmax(20px, 65px);
  gap: 1px 1px;
  position: fixed;
  z-index: 1;
  width: 100%;

  @media (max-width: 960px) {
    grid-template-columns: ${({ isLogged }) => (isLogged ? '20% 79%;' : '35% 65%;')};
    position: relative;
  }
  @media (max-width: 960px) {
    grid-template-columns: ${({ isLogged }) => (isLogged ? '20% 79%;' : '30% 70%;')};
    position: relative;
  }
`

export const LogoContainer = styled.div``

export const BurgerIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  padding-right: 0px;
`
export const HeaderLogo = styled.div`
background: url('${({ background }) => background}');
background-repeat: no-repeat;
  height: 70%;
  background-position-x: 10px;
  @media (max-width: 600px){
height: 70%;
width: 150px;
background-position-x: 10px;
background-size: 80% 100%;
margin-top: 15px
  }

`
export const Action = styled(Button)`
  margin-left: 1rem;
  font-size: 0.7rem;
`
export const Logo = styled(Link)`
  text-decoration: none;
  width: 100%;
  padding: 10px 0 0 20px;
  @media (max-width: 600px) {
    padding: 0;
  }
`
