import React from 'react'
import { Typography } from '@material-ui/core'
import FacebookSVG from 'assets/home/Facebook-btn.svg'
import InstagramSVG from 'assets/home/Instagram-btn.svg'
import TwitterSVG from 'assets/home/Twitter-btn.svg'
import WhatsAppSVG from 'assets/home/WhatsApp-btn.svg'
import { SocialBannerContainer, SocialBtn, SocialBtnGrid } from './styles'


const SocialBanner = (props) => {
  const socialSvgs = [FacebookSVG, TwitterSVG, InstagramSVG, WhatsAppSVG]
  return (
    <SocialBannerContainer>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 700, textAlign: 'center' }}>
        ESTAMOS CERCA
      </Typography>
      <SocialBtnGrid>
        {socialSvgs.map((btn, index) => (
          <SocialBtn key={index} source={btn} />
        ))}
      </SocialBtnGrid>
    </SocialBannerContainer>
  )
}

export default SocialBanner
