import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ErrorBoundary } from 'components/ErrorBoundary'

export const AuthRoute = ({
    isAuthenticated,
    component: Component,
    redirect: pathname,
    restricted,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated && !restricted ? (
                    <ErrorBoundary>
                        <Component {...props} />
                    </ErrorBoundary>
                ) : (
                        <Redirect to={{ pathname: pathname }} />
                    )
            }
        />
    );
};