import styled from 'styled-components'

export const RedirectContainer = styled.div`
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  background: white;
  right: 0;
  left: 0;
  bottom: 0;
  & > div.text {
    font-size: 1.25rem;
    font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    position: absolute;
    top: 25%;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  & > div.spinner {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 40%;
    margin: 0 auto;
  }
`
