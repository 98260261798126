import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1.5em;
  font-weight: 900;
  padding: 20px 45px 5px 45px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    font-size: 1em;
  }
`
export const NavTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1em;
  font-weight: 900;
`

export const SubTitle = styled(Typography)`
  padding: 20px 10px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 12px;
  font-weight: 900;
  text-align: center;
`
export const TextsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 50%;
  }
`

export const ContainerBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('${({ background }) => background}');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 93% 60%;
  z-index: 1;
  background-position-x: center;
  background-position-y: 80%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    background-size: 50% 50%;
  }
`

export const ContainerBackgroundError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('${({ background }) => background}');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 93% 60%;
  z-index: 1;
  background-position-x: center;
  background-position-y: 80%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    background-size: 50% 50%;
  }
`

export const Image = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('${(props) => props.src}');
  background-repeat: no-repeat;
  background-size: 93% 60%;
  z-index: 1;
  background-position-x: center;
  background-position-y: 35vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    & > button {
      width: 15vw;
    }
  }
`
export const StickyNav = styled.div`
  width: 99vw;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  z-index: 5;
`

export const Container = styled.div`
  background: #29235c 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    scroll: overflow;
  }
`

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 35vh;
  @media (max-width: 990px) {
    height: 200px;
  }
`
