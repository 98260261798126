import { RESET_STATE, RESET_PASSWORD_COMPLETE, RESET_PASSWORD_START, RESET_PASSWORD_ERROR } from 'redux/actionTypes'

const defaultState = {
  isSuccess: false,
  isLoading: false,
  error: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return { ...state, isLoading: true }
    case RESET_PASSWORD_COMPLETE:
      return { ...state, isLoading: false, isSuccess: action.isSuccess }
    case RESET_PASSWORD_ERROR:
      return { ...state, isLoading: false, error: action.payload }
    case RESET_STATE: return defaultState;
    default:
      return state
  }
}
