import React from 'react'
import { SliderRefs, StyledSlider } from './styles'
import { Typography } from '@material-ui/core'

const CalculatorSelector = ({ name, formatedValue, value, minText, maxText, min, max, onChange, step }) => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {name}
      </Typography>
      <Typography align="center" variant="h2" gutterBottom>
        {formatedValue}
      </Typography>
      <StyledSlider
        color="secondary"
        onChange={(e, c) => onChange(c)}
        value={value}
        valueLabelDisplay="off"
        step={step}
        min={min}
        max={max}
      />
      <SliderRefs>
        <Typography variant="body1" gutterBottom>
          {minText} (MIN)
        </Typography>
        <Typography variant="body1" gutterBottom>
          {maxText} (MAX)
        </Typography>
      </SliderRefs>
    </>
  )
}

export default CalculatorSelector
