import axios from 'axios'

import {
  RESET_STATE,
  PUT_DNI_IMAGES_START,
  PUT_DNI_IMAGES_ERROR,
  BASE_URL_API,
  PUT_DNI_IMAGES_COMPLETE_SUCCESS,
} from 'redux/actionTypes'

export const putDniImages = (userId, images, tramitNumber) => async (dispatch) => {
  dispatch({ type: PUT_DNI_IMAGES_START })

  const fd = new FormData()
  fd.append('dni_front', images[0], images[0].name)
  fd.append('dni_back', images[1], images[1].name)
  fd.append('idtramite', tramitNumber)

  const token = localStorage.getItem('jwt')
  const config = {
    headers: {
      authorization: token,
      'content-type': 'multipart/form-data',
    },
  }

  try {
    const req = await axios.post(BASE_URL_API + `/api/users/update-dni-images/${userId}`, fd, config)
    dispatch({
      type: PUT_DNI_IMAGES_COMPLETE_SUCCESS,
      payload: req.data,
    })
  } catch (error) {
    dispatch({
      type: PUT_DNI_IMAGES_ERROR,
      payload: error?.response?.message ?? 'Error al subir la información',
    })
  }
}

export const resetDniState = () => async (dispatch) => {
  dispatch({ type: RESET_STATE })
}
