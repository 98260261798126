import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RedirectContainer } from './styled'

const Spinner = ({ showSpinner }) => {
  return (
    showSpinner && (
      <RedirectContainer>
        <div className="text">
          <span style={{ color: 'black' }}>Un momento, estamos procesando su información.</span>
        </div>
        <div className="spinner">
          <CircularProgress size={150} color="primary" />
        </div>
      </RedirectContainer>
    )
  )
}

export default Spinner
