import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Pill from '../PIll'
import moment from 'moment'

const columns = [
  { id: 'cuota', label: 'Cuota n°', minWidth: 170 },
  { id: 'monto', label: 'Monto', minWidth: 100 },
  {
    id: 'vencimiento',
    label: 'Vencimiento',
    minWidth: 170,
    align: 'right',
    format: (value) => moment(value).format('YYYY-MM-DD'),
  },
  {
    id: 'estado',
    label: 'Estado',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'id',
    label: 'Id',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
]

const feesStatusMsg = (state_id) => {
  switch (state_id) {
    case 1:
      return <Pill type={state_id} name="NO PAGADA" />
    case 2:
      return <Pill type={state_id} name="PENDIENTE" />
    case 3:
      return <Pill type={state_id} name="PAGADA" />
    default:
      return ''
  }
}

function createData(cuota, monto, id, estado, vencimiento) {
  return { cuota, monto, id, estado: feesStatusMsg(estado), vencimiento }
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
})

function FeesTable({ fees }) {
  const classes = useStyles()

  const rowsData = fees.map(({ amount, fee_number, fee_state_id, payment_date, id }, i) =>
    createData(fee_number, parseFloat(amount).toFixed(2), id, fee_state_id, moment(payment_date).format('DD-MM-YYYY'))
  )

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  {columns.map((column) => {
                    const value = row[column.id]

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value || '*'}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default FeesTable
