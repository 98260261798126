export const faqsMap = [
  {
    id: 0,
    title: '¿Cómo me registro?',
    body: 'Podés registrarte desde la web haciendo <a href="/registrarse">click acá</a></p> o bien en el momento de pedir un préstamo, donde se te solicitarán tus datos personales.',
  },
  {
    id: 1,
    title: '¿Cuáles son los pasos para pedir el préstamo?',
    flexDirection: 'column',
    body: `<p style="margin: 0;">Registrarte desde la web y completar todos tus datos</p>
        <ul><li> Validar tu identidad con una foto de tu DNI</li><li> Validar tu correo electrónico</li></ul>
        ¡ Y listo, ya podés pedir tu préstamo !`,
  },
  {
    id: 2,
    title: '¿Cuáles son los requisitos para pedir un préstamo?',
    flexDirection: 'column',
    body: `<p style="margin:0"> Los requisitos indispensables son:</p>
       <ul>
        <li>Ser mayor de 18 años y residir en Argentina</li>
        <li>Tener algún tipo de ingreso</li>
        <li>Poseer una cuenta bancaria a tu nombre, que no corresponda a un plan social</li>
        <li>Enviar una imagen de tu DNI para garantizar que el proceso sea seguro</li>
        </ul>
        <p style="margin:0">Si cumplís con estos requisitos, registrate haciendo click <a href="/registrarse">acá</a></p>`,
  },
  {
    id: 3,
    title: '¿Cuánto puedo pedir?',
    body: `El monto de tu línea de crédito lo define el sistema según el resultado de tu análisis crediticio, siendo $50.000 el mínimo y $300.000 el máximo. Si estás al día con el pago de las cuotas tu disponible aumentará.`,
  },
  {
    id: 4,
    title: '¿Cuál es el tiempo de acreditación?',
    body: 'Una vez que confirmas tu solicitud de préstamo, la acreditación es instantánea siempre y cuando la cuenta bancaria sea correcta. Recordá que si el CBU corresponde a un plan social no voy a poder hacer la acreditación.',
  },
  {
    id: 5,
    title: '¿Cómo pago el préstamo?',
    flexDirection: 'column',
    body: ` <p style="margin:0">El préstamo vence el primer día hábil del mes siguiente. Vas a poder hacer tu pago con:</p>
      <ul>
        <li>Con tu Tarjeta de débito</li>
        <li> Por trasferencia o depósito en mi cuenta bancaria</li>
        <li> Pagando con código de barras en Rapipago, Pago Fácil y otras agencias de cobro.</li>
      </ul>
      Si lo solicitas a partir del día 26 la fecha de vencimiento cambia al mes subsiguiente.`,
  },
]
