import { GET_LOAN_BY_USER_COMPLETE, GET_LOAN_BY_USER_ERROR, GET_LOAN_BY_USER_START } from '../../actionTypes'
const initialState = {
  isLoading: false,
  success: false,
  didError: false,
  data: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOAN_BY_USER_START:
      return { ...state, isLoading: true }
    case GET_LOAN_BY_USER_COMPLETE:
      return { ...state, isLoading: false, success: true, data: payload }
    case GET_LOAN_BY_USER_ERROR:
      return { ...state, isLoading: false, didError: true }
    default:
      return state
  }
}
