import { combineReducers } from 'redux'

import auth from './auth/signIn'
import signUp from './auth/signUp'
import verifyAccount from './auth/verifyAccount'
import modifyPassword from './modifyPassword/modifyPassword'
import newPassword from './modifyPassword/newPassword'
import loan from './loan/loan'
import loanUser from './loan/loanUser'
import simulateLoan from './loan/simulateLoan'
import dni from './dni/dni'
import varEconomic from './varEconomic/varEconomic'
import scoring from './scoring/scoring'
import bankAccount from './bankAccount/bankAccount'
import { appNotificationReducer } from './notification'

const reducers = combineReducers({
  auth,
  bankAccount,
  signUp,
  verifyAccount,
  modifyPassword,
  newPassword,
  loan,
  loanUser,
  simulateLoan,
  dni,
  varEconomic,
  scoring,
  appNotificationReducer
})

export default reducers
