import styled from 'styled-components'
import { ReactComponent as MoneyIconSVG } from 'assets/home/money-icon.svg'

export const MoneyIcon = styled(MoneyIconSVG)`
  height: 50px;
  margin-bottom: 1rem;
`
export const LoanCalculatorContainer = styled.div`
  height: 150px;
  text-align: center;

`