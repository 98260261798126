import React from "react";
import cx from "classnames";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from './styles'


export const ErrorComponent = ({ title, subtitle, email, restartFn }) => {
  const classes = useStyles();
  return (
    <Grid id="error" container justify="center" className={classes.root}>
      <Grid item xs={12} md={9} container alignItems="flex-start">
        <Grid item xs={12} sm={7} className={classes.leftSection}>
          <Typography
            variant="h4"
            gutterBottom
            color="secondary"
            className={classes.title}
          >
            {title || "¡Lo sentimos!"}
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.subtitle}>
            {subtitle || "Algo no funcionó como esperabamos"}
          </Typography>
          <div className={classes.content}>
            Para más información, por favor{" "}
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
              className={cx("link", classes.link)}
            >
              contáctenos
            </a>
            .
          </div>
        </Grid>
        <Grid item className={classes.verticalBorder} />
        <Grid item xs={12} sm={4}>
          <div className={classes.contact}>
            <div>
              <i className={cx("bf-icon-phone", classes.icon)} />
              Número:
            </div>
            <div>
              <i className={cx("bf-icon-mail", classes.icon)} />
              Email:{" "}
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.mail}
              >
                soporte@loan.com.ar
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};