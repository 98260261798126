import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'

import TagManager from 'react-gtm-module'
/* Theme */
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { mainTheme } from 'theme/theme'
import CssBaseline from '@material-ui/core/CssBaseline'

import { Provider } from 'react-redux'
import { configureStore } from 'redux/store'

const theme = createMuiTheme(mainTheme)

const TagManagerArgs = {
  gtmId: 'GTM-MZCBF8W',
}

TagManager.initialize(TagManagerArgs)

ReactDOM.render(
  <Provider store={configureStore()}>
    <CssBaseline />
    <ThemeProviderStyled theme={mainTheme}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ThemeProviderStyled>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
