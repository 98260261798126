import React, { useState, useRef, cloneElement, useImperativeHandle, forwardRef, useCallback } from 'react'
import { BurgerIcon } from 'styles'
import { NavButton, Container } from './styles'
import { useClickOutside } from '../../hooks'
import ClearIcon from '@material-ui/icons/Clear'

const BurgerMenu = forwardRef(({ isHome, children }, ref) => {
  const menuRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [clickOutsideEnabled, setClickOutsideEnabled] = useState(true)

  const toggleBurgerMenu = useCallback((value) => {
    setOpen(value)
  }, [])

  useClickOutside(menuRef, () => {
    if (clickOutsideEnabled) {
      open && setOpen(false)
    }
  })

  useImperativeHandle(
    ref,
    () => ({
      open,
      toggleBurgerMenu,
      setClickOutsideEnabled,
    }),
    [open, toggleBurgerMenu]
  )

  return (
    <Container>
      {clickOutsideEnabled && (
        <NavButton ref={menuRef} onClick={() => toggleBurgerMenu(!open)} isHome={isHome}>
          {open ? <ClearIcon className={'close'} onClick={toggleBurgerMenu} /> : <BurgerIcon name="hamburger" />}
        </NavButton>
      )}
      {open &&
        cloneElement(children, {
          ref: menuRef,
          toggleBurgerMenu: toggleBurgerMenu,
          setClickOutsideEnabled,
          open,
        })}
    </Container>
  )
})

export default BurgerMenu
