import styled from 'styled-components'
import { Icon } from 'components/Icon/Icon'

import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const BurgerIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  padding-right: 0px;
`
export const HeaderContainer = styled.div`
  background: ${({ isHomeUser }) => isHomeUser && '#1EC0CA'};
  height: 65px;
  padding: 1.1rem 3rem;
  display: flex;
  justify-content: space-between;
`
export const HeaderLogo = styled.div`
background: url('${({ background }) => background}');
background-repeat: no-repeat;
height: 70%;
width: 150px;
background-position-x: 10px;
margin-top: 15px;
}
`

export const Action = styled(Button)`
  margin-left: 1rem;
  font-size: 0.7rem;
  color: white;
`
export const Bond = styled(Link)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: ${({ bondFromHeader }) => bondFromHeader && 'none'};
  }
`
export const Logo = styled(Link)`
  text-decoration: none;
  width: calc(100% - 25px);
`
