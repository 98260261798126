import React from 'react'
import { SummaryItem, SummaryItemText } from './styled'
import FeesTable from 'components/FeesTable'
import { CircularProgress, Grid } from '@material-ui/core'

const WrappedComponent = ({ detailsData }) => {
  const { fees, interest, loanId, simulatedAmount } = detailsData

  return (
    <>
      <SummaryItem>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          ID TRANSACCIÓN
        </SummaryItemText>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          {`# ${loanId}`}
        </SummaryItemText>
      </SummaryItem>
      {simulatedAmount && (
        <SummaryItem>
          <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
            Monto solicitado
          </SummaryItemText>
          <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
            {`$ ${simulatedAmount}`}
          </SummaryItemText>
        </SummaryItem>
      )}
      <SummaryItem>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          INTERÉS CAPITALIZADO
        </SummaryItemText>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          {`${interest} %`}
        </SummaryItemText>
      </SummaryItem>
      <SummaryItem>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          CUOTAS
        </SummaryItemText>
        <SummaryItemText variant="caption" style={{ color: '#808080' }} gutterBottom>
          {fees && fees.length ? fees.length : 'NO APLICA'}
        </SummaryItemText>
      </SummaryItem>
      <Grid justify="center" align="center"></Grid>
      {fees && fees.length ? <FeesTable fees={fees} /> : null}
    </>
  )
}

export default WrappedComponent
