import React from 'react'
import { Container, ContentText, Content } from './styles'

import { Typography, Button } from '@material-ui/core'

const Validate = ({ isHomeUser }) => {
  return (
    <Container isHomeUser={isHomeUser}>
      <Content>
        <ContentText>
          <Typography variant="body1" paragraph={false} gutterBottom>
            Completá tus datos personales para poder validar tu cuenta y solicitar tu préstamo
          </Typography>
        </ContentText>
        <Button variant="contained" color="secondary" href="/user/estado-de-cuenta">
          VALIDAR INFORMACION
        </Button>
      </Content>
    </Container>
  )
}

export default Validate
