import React from 'react'
import { GenericNav } from './styles'

const StickyNav = (props) => {
  const { isFixed = false } = props
  return (
    <GenericNav isFixed={isFixed} {...props}>
      {props.children}
    </GenericNav>
  )
}

export default StickyNav
