import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'components/ErrorBoundary'

export const PrivateRoute = ({
    component: Component,
    redirect: pathname,
    restricted,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                restricted ? (
                    <Redirect to={{ pathname: pathname }} />
                ) : (
                        <ErrorBoundary>
                            <Component {...props} />
                        </ErrorBoundary>
                    )
            }
        />
    );
};
