import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LoanLogo from 'assets/img/loanlogo.svg'
import BlockIcon from '@material-ui/icons/Block'
import { Link, withRouter } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CachedIcon from '@material-ui/icons/Cached'
import PersonIcon from '@material-ui/icons/Person'
import { MenuContainer, HeaderLogo } from './styles'
import { actionSignOut } from 'redux/actions/auth/signOut.js'
import { useDispatch } from 'react-redux'

const MenuOptions = ({ isOpen, isFixed = false, history }) => {
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(actionSignOut())
  }
  return (
    <MenuContainer isFixed={isFixed}>
      <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </Link>

      <Link to="/user" style={{ textDecoration: 'none', color: '#fff' }}>
        <ListItem button>
          <ListItemIcon>
            <CachedIcon />
          </ListItemIcon>
          <ListItemText primary="Mis prestamos" />
        </ListItem>
      </Link>
      <Link exact to="/user/estado-de-cuenta" style={{ textDecoration: 'none', color: '#fff' }}>
        <ListItem button>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Estado de cuenta" />
        </ListItem>
      </Link>
      <Link exact to="/user/cbu-info" style={{ textDecoration: 'none', color: '#fff' }}>
        <ListItem button>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="CBU INFO" />
        </ListItem>
      </Link>
      <Link to="/user/perfil" style={{ textDecoration: 'none', color: '#fff' }}>
        <ListItem button>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Mi Perfil" />
        </ListItem>
      </Link>
      <Link to="/" style={{ textDecoration: 'none', color: 'white' }} onClick={() => logOut()}>
        <ListItem button>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Link>
      <HeaderLogo background={LoanLogo}>
        <span role="img" aria-label="loan">LOAN ®️ {new Date().getFullYear()}</span>
      </HeaderLogo>
    </MenuContainer>
  )
}

export default withRouter(MenuOptions)
