import React from 'react'
import { Paper } from '@material-ui/core'
import { useStyles, FullSizeGrid } from '../LoanItem/styles'
const NoLoansMessages = ({ principalMsg, secondaryMsg }) => {
  const classes = useStyles()
  return (
    <FullSizeGrid align="center" xs={12} md={6}>
      <Paper className={classes.paperPrimary}>
        <h4 className={classes.h4}>{principalMsg}</h4>
        <p>{secondaryMsg}</p>
      </Paper>
    </FullSizeGrid>
  )
}

export default NoLoansMessages
