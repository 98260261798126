import React from 'react'
import { FooterContainer, SupportButton } from './styles'
import background from 'assets/register/footer-background.svg'

const RegisterFooter = ({ signUp, specialHeight }) => {
  return (
    <FooterContainer signUp={signUp} specialHeight={specialHeight} src={background}>
      <SupportButton variant="contained" color="default">
        Soporte
      </SupportButton>
    </FooterContainer>
  )
}

export default RegisterFooter
