export const BASE_URL_API = process.env.REACT_APP_API

export const SEARCH_LOGIN_START = 'SEARCH_LOGIN_START'
export const SEARCH_LOGIN_COMPLETE = 'SEARCH_LOGIN_COMPLETE'
export const SEARCH_LOGIN_ERROR = 'SEARCH_LOGIN_ERROR'
export const SIGN_OUT = 'SIGN_OUT'

export const SEARCH_SIGN_UP_START = 'SEARCH_SIGN_UP_START'
export const SEARCH_SIGN_UP_COMPLETE = 'SEARCH_SIGN_UP_COMPLETE'
export const SEARCH_SIGN_UP_ERROR = 'SEARCH_SIGN_UP_ERROR'

export const VALIDATE_ACCOUNT_START = 'VALIDATE_ACCOUNT_START'
export const VALIDATE_ACCOUNT_COMPLETE = 'VALIDATE_ACCOUNT_COMPLETE'
export const VALIDATE_ACCOUNT_ERROR = 'VALIDATE_ACCOUNT_ERROR'

export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER'

export const POST_RESET_PASSWORD_START = 'POST_RESET_PASSWORD_START'
export const POST_RESET_PASSWORD_COMPLETE = 'POST_RESET_PASSWORD_COMPLETE'
export const POST_RESET_PASSWORD_ERROR = 'POST_RESET_PASSWORD_ERROR'

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_COMPLETE = 'RESET_PASSWORD_COMPLETE'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const CREATE_LOAN_COMPLETE = 'CREATE_LOAN_COMPLETE'
export const CREATE_LOAN_ERROR = 'CREATE_LOAN_ERROR'
export const CREATE_LOAN_START = 'CREATE_LOAN_START'
export const FETCH_LAST_LOAN = 'FETCH_LAST_LOAN'

export const PUT_DNI_IMAGES_START = 'PUT_DNI_IMAGES_START'
export const PUT_DNI_IMAGES_COMPLETE_SUCCESS = 'PUT_DNI_IMAGES_COMPLETE_SUCCESS'
export const PUT_DNI_IMAGES_ERROR = 'PUT_DNI_IMAGES_ERROR'

export const GET_LOAN_BY_USER_START = 'GET_LOAN_BY_USER_START'
export const GET_LOAN_BY_USER_COMPLETE = 'GET_LOAN_BY_USER_COMPLETE'
export const GET_LOAN_BY_USER_ERROR = 'GET_LOAN_BY_USER_ERROR'

export const GET_SIMULATE_LOAN_START = 'GET_SIMULATE_LOAN_START'
export const GET_SIMULATE_LOAN_COMPLETE = 'GET_SIMULATE_LOAN_COMPLETE'
export const GET_SIMULATE_LOAN_ERROR = 'GET_SIMULATE_LOAN_ERROR'

export const GET_VAR_ECONOMIC_START = 'GET_VAR_ECONOMIC_START'
export const GET_VAR_ECONOMIC_COMPLETE = 'GET_VAR_ECONOMIC_COMPLETE'
export const GET_VAR_ECONOMIC_ERROR = 'GET_VAR_ECONOMIC_ERROR'

export const GET_SCORING_START = 'GET_SCORING_START'
export const GET_SCORING_COMPLETE = 'GET_SCORING_COMPLETE'
export const GET_SCORING_ERROR = 'GET_SCORING_ERROR'
export const GET_USER_BANK_ACOUNT_START = 'GET_USER_BANK_ACOUNT_START'
export const GET_USER_BANK_ACOUNT_COMPLETE = 'GET_USER_BANK_ACOUNT_COMPLETE'
export const GET_USER_BANK_ACOUNT_ERROR = 'GET_USER_BANK_ACOUNT_ERROR'

export const POST_USER_BANK_ACOUNT_START = 'POST_USER_BANK_ACOUNT_START'
export const POST_USER_BANK_ACOUNT_COMPLETE = 'POST_USER_BANK_ACOUNT_COMPLETE'
export const POST_USER_BANK_ACOUNT_ERROR = 'PST_USER_BANK_ACOUNT_ERROR'

export const NOTIFICATION_EDIT = 'NOTIFICATION_EDIT'
export const RESET_STATE = 'RESET_STATE'
