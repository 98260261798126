import styled from 'styled-components'

export const SocialBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 15vh;
  margin: 2rem 0;
  padding-bottom: 3rem;
`

export const SocialBtn = styled.div`
  height: 70px;
  width: 70px;
  background-image: url(${(props) => props.source});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 50px;
    width: 50px;
  }
`
export const SocialBtnGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 35%;
  width: 100%;
  & > * {
    /* margin-left: 15px; */
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-top: 15px;
    width: 100%;
    max-width: 100%;
  }
`
