import { Grid } from '@material-ui/core'
import {
  LoanAction,
  LoanCalculatorContainer,
  ModalWrapper,
  SummaryContainer,
  SummaryItem,
  SummaryItemText,
} from './styles'
import React, { useEffect, useRef, useState } from 'react'
import { getSimulateLoan, loanCreate, resetLoanState } from 'redux/actions/loan/actionLoan'
import { useDispatch, useSelector } from 'react-redux'

import CalculatorSelector from 'components/CalculatorSelector'
import LoanDetails from 'components/LoanDetails'
import Modal from 'components/Modal'
import { getFormatedThousands } from 'helpers/getFormatedThousands'
import { getScoring } from 'redux/actions/scoring/scoring'
import { getVarEconomic } from 'redux/actions/varEconomic/varEconomic'
import { useNotification } from 'hooks/useNotification'
import Spinner from 'components/Spinner'

const LoanCalculator = ({ isIndex, history, userNotLoggedIn }) => {
  /* Loan amount */
  const loanCalculatorRef = useRef(null);
  const [amount, setAmount] = useState(null)
  const formattedAmount = '$' + getFormatedThousands(amount)

  /* Loan term */
  const [term, setTerm] = useState(null)
  const formattedTerm = term + ' Meses'

  // Modal
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [bodyModal, setBodyModal] = useState('')

  //Modal de simulacion de prestamos
  const [openModalSimulateLoan, setOpenModalSimulateLoan] = useState(false)

  /* Constant-redux */
  //autentificacion
  const user = useSelector(({ auth }) => auth.user)
  const { isLoading: isLoadingVarEconomic, isSuccess: isSuccessVarEconomic, varEconomic } = useSelector(
    ({ varEconomic }) => varEconomic
  )
  const { isLoading: isLoadingScoring, isSuccess: isSuccessScoring, scoring } = useSelector(({ scoring }) => scoring)
  const { statusCode, hasError: isLoanCreateError, isLoading, msg: loanCreateMessage } = useSelector(({ loan }) => loan)

  //si el prestamo fue creado correctamente
  //simulacion de un prestamo
  // esto no debería suceder acá, no nos abstraemos de lo que es un préstamo como tal y una simulación.
  const { feesList, hasError: hasErrorSimulateLoan, isLoading: isLoadingSimulate } = useSelector(
    ({ simulateLoan }) => simulateLoan
  )

  const handleClose = () => {
    if (open) setOpen(false)
    if (openModalSimulateLoan) setOpenModalSimulateLoan(false)
  }

  // notification
  const { showNotification, closeNotification } = useNotification(handleClose)

  const dispatch = useDispatch()

  const sendLoan = () => {
    handleClose()
    if (userNotLoggedIn) {
      return history.push('/ingresar')
    }
    dispatch(loanCreate(user, amount, term, scoring.tna))
  }

  const sendSimulateLoan = async () => {
    await dispatch(getSimulateLoan(amount, term))
    setOpenModalSimulateLoan(true)
  }

  useEffect(() => {
    dispatch(getVarEconomic())
    dispatch(getScoring())
  }, [dispatch])

  useEffect(() => {
    if (isSuccessVarEconomic) {
      const { min_amount, max_amount, min_amount_of_fees, max_amount_of_fees } = varEconomic
      setAmount(Math.trunc((min_amount + max_amount) / 2))
      setTerm(Math.trunc((min_amount_of_fees + max_amount_of_fees) / 2))
    }
  }, [isSuccessVarEconomic, varEconomic])

  useEffect(() => {
    !!hasErrorSimulateLoan && showNotification('Error al simular prestamo. Intente nuevamente mas tarde.', 'error')
  }, [hasErrorSimulateLoan, showNotification])

  useEffect(() => {
    if (isLoanCreateError) {
      history.push(statusCode === 403 ? '/user/estado-de-cuenta' : '/user')
      showNotification(statusCode === 403 ? 'Por favor, completa tu información' : loanCreateMessage, 'error')
      dispatch(resetLoanState())
    } else if (statusCode == 400) {
      history.push('/user')
      showNotification(loanCreateMessage, 'warning')
      dispatch(resetLoanState())
    }
  }, [dispatch, history, isLoanCreateError, loanCreateMessage, showNotification, statusCode])

  useEffect(() => {
    if (statusCode === 200) {
      history.push('/user')
      showNotification('Prestamo creado correctamente', 'success')
      dispatch(resetLoanState())
    }
  }, [dispatch, history, loanCreateMessage, showNotification, statusCode])

  const { state } = history.location

  const redirectedFromUser = state?.from === "/user-view"

  useEffect(() => {
    const domRect = loanCalculatorRef?.current?.getBoundingClientRect();
    if (redirectedFromUser && !isLoading && !isLoadingSimulate) {
      // esto es para que luego del loading se pueda ver el efecto smooth.
      window.scrollTo({
        //65px del header & 35px padding del contenedor de los subtitulos
        top: domRect.top - 65 - 35,
        behavior: 'smooth'
      });
      showNotification("Primero, elegí un MONTO y un PLAZO para que adecuemos el préstamo según tus necesidades.", "info")
      setTimeout(() => {
        history.replace({ pathname: history.location.pathname, state: {} })
      }, 2000)
    }
  }, [history, isLoading, isLoadingSimulate, redirectedFromUser, showNotification])

  return (
    <LoanCalculatorContainer ref={loanCalculatorRef}>
      <CalculatorSelector
        name="MONTO"
        onChange={setAmount}
        value={amount}
        formatedValue={formattedAmount}
        min={(!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.min_amount) || 'error '}
        max={(!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.max_amount) || 'error '}
        minText={getFormatedThousands((!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.min_amount) || 0)}
        maxText={getFormatedThousands((!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.max_amount) || 0)}
        step={500}
      />

      <CalculatorSelector
        name="PLAZO"
        onChange={setTerm}
        value={term}
        formatedValue={formattedTerm}
        min={(!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.min_amount_of_fees) || 'error '}
        max={(!isLoadingVarEconomic && isSuccessVarEconomic && varEconomic.max_amount_of_fees) || 'error '}
        minText={
          (!isLoadingVarEconomic && isSuccessVarEconomic && `${varEconomic.min_amount_of_fees} Meses`) || 'error '
        }
        maxText={
          (!isLoadingVarEconomic && isSuccessVarEconomic && `${varEconomic.max_amount_of_fees} Meses`) || 'error '
        }
        step={1}
      />
      <SummaryContainer>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            TNA
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {(!isLoadingScoring && isSuccessScoring && `${scoring.tna}%`) || 'error en el servidor'}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            Préstamo
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {formattedAmount}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            Fecha de pago
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            Del 1 al 10 de cada mes
          </SummaryItemText>
        </SummaryItem>
      </SummaryContainer>
      <Spinner showSpinner={isLoading || isLoadingSimulate} />
      <Grid>
        <LoanAction redirectedFromUser={redirectedFromUser} onClick={() => sendSimulateLoan()} variant="contained" color="secondary">
          SIMULAR PRESTAMO
        </LoanAction>
      </Grid>
      <Modal
        onClose={handleClose}
        open={open}
        modalTitle={modalTitle}
        titleButtonAcept={'Aceptar'}
        titleButtonCancel={'Cancelar'}
        handleButtonAcept={() => handleClose()}
        handleButtonCancel={() => handleClose()}
      >
        <div>{bodyModal}</div>
      </Modal>

      <ModalWrapper
        onClose={handleClose}
        open={openModalSimulateLoan}
        modalTitle={'DETALLES DE TU PRÉSTAMO SIMULADO'}
        titleButtonAcept={'Solicitar Prestamo'}
        titleButtonCancel={'Simular otro prestamo'}
        handleButtonAcept={sendLoan}
        handleButtonCancel={handleClose}
      >
        <LoanDetails
          tails
          detailsData={{
            fees: feesList,
            interest: (!isLoadingScoring && isSuccessScoring && `${scoring.tna}`) || 'error en el servidor',
            loanId: 'Simulacion',
            simulatedAmount: amount,
          }}
        />
      </ModalWrapper>
    </LoanCalculatorContainer>
  )
}

export default LoanCalculator
