export const listadoBancos = {
    "banks": {
        "Banco de la Nacion Argentina": "Banco de la Nacion Argentina",
        "Banco de La Pampa": "Banco de La Pampa",
        "Banco de la Provincia de Buenos Aires": "Banco de la Provincia de Buenos Aires",
        "Banco de Tierra de Fuego": "Banco de Tierra de Fuego",
        "Banco de Valores SA": "Banco de Valores SA",
        "Banco del Buen Ayre": "Banco del Buen Ayre",
        "Banco Francés": "Banco Francés",
        "Banco Galicia y Buenos Aires S.A.": "Banco Galicia y Buenos Aires S.A.",
        "Banco General de Negocios S.A.": "Banco General de Negocios S.A.",
        "Banco Hipotecario S.A.": "Banco Hipotecario S.A.",
        "Banco Itaú": "Banco Itaú",
        "Banco Julio S.A.": "Banco Julio S.A.",
        "Banco Macro S.A.": "Banco Macro S.A.",
        "Banco Mariva": "Banco Mariva",
        "Banco Mercurio S.A.": "Banco Mercurio S.A.",
        "BANCO MUNICIPAL DE ROSARIO": "BANCO MUNICIPAL DE ROSARIO",
        "Banco Patagonia S.A.": "Banco Patagonia S.A.",
        "Banco Piano": "Banco Piano",
        "Banco Privado de Inversiones S.A.": "Banco Privado de Inversiones S.A.",
        "Banco Regional de Cuyo S.A.": "Banco Regional de Cuyo S.A.",
        "Banco Río": "Banco Río",
        "Banco Saenz S.A.": "Banco Saenz S.A.",
        "Banco Sudameris Argentina S.A.": "Banco Sudameris Argentina S.A.",
        "Banco Supervielle": "Banco Supervielle",
        "Banco Suquia S.A.": "Banco Suquia S.A.",
        "Bank Leumi": "Bank Leumi",
        "Bank of America, NA": "Bank of America, NA",
        "Bank of Tokyo-mitsubishi Ufj": "Bank of Tokyo-mitsubishi Ufj",
        "BankBoston": "BankBoston",
        "Banque Nationale de Paris Argentina": "Banque Nationale de Paris Argentina",
        "Banque Safdie": "Banque Safdie",
        "Bansud": "Bansud",
        "Barclays Bank Plc": "Barclays Bank Plc",
        "Barclays Wealth": "Barclays Wealth",
        "Bica Cooperativa de Emprendimientos Múltiples Limitada": "Bica Cooperativa de Emprendimientos Múltiples Limitada",
        "BNP Paribas": "BNP Paribas",
        "C.C.CLa Capital de Plata": "C.C.CLa Capital de Plata",
        "Citibank (Argentina)": "Citibank (Argentina)",
        "Citibank Argentina": "Citibank Argentina",
        "Clariden Bank SA": "Clariden Bank SA",
        "Corp Banca S.A.": "Corp Banca S.A.",
        "Credilogros Compania Financiera SA": "Credilogros Compania Financiera SA",
        "Credit Suisse (Investment Banking)": "Credit Suisse (Investment Banking)",
        "DaimlerChrysler Financiera": "DaimlerChrysler Financiera",
        "Deutsche Bank Argentina S.A.": "Deutsche Bank Argentina S.A.",
        "E-Galicia.com": "E-Galicia.com",
        "Fiat Credito Compania Financiera": "Fiat Credito Compania Financiera",
        "Ford Credit Compania Financiera": "Ford Credit Compania Financiera",
        "GE Money Bank GmbH": "GE Money Bank GmbH",
        "Goldman Sachs Argentina L.L.C.": "Goldman Sachs Argentina L.L.C.",
        "HSBC": "HSBC",
        "ING Bank N.V-Branch": "ING Bank N.V-Branch",
        "Israel Discount Bank Ltd.": "Israel Discount Bank Ltd.",
        "JOHN DEERE CREDIT COMPAÑIA FINANCIERA": "JOHN DEERE CREDIT COMPAÑIA FINANCIERA",
        "JPMorgan Chase Bank, National Association": "JPMorgan Chase Bank, National Association",
        "Lloyds Bank": "Lloyds Bank",
        "Masventas S.A Compania Financiera": "Masventas S.A Compania Financiera",
        "MBA Banco de Investitiones": "MBA Banco de Investitiones",
        "Nuevo Banco Bisel S.A.": "Nuevo Banco Bisel S.A.",
        "Nuevo Banco de Chaco S.A.": "Nuevo Banco de Chaco S.A.",
        "Nuevo Banco de la Rioja S.A.": "Nuevo Banco de la Rioja S.A.",
        "Nuevo Banco de Santa Fe": "Nuevo Banco de Santa Fe",
        "Nuevo Banco Industrial de Azul": "Nuevo Banco Industrial de Azul",
        "RCI Banque": "RCI Banque",
        "Schroder Mildesa Investment Management S.A.": "Schroder Mildesa Investment Management S.A.",
        "Standard Bank": "Standard Bank",
        "Standard Chartered Bank": "Standard Chartered Bank",
        "Toyota Compania Financiera de Argentina S.A": "Toyota Compania Financiera de Argentina S.A",
        "Tutelar Compania Financiera S.A.": "Tutelar Compania Financiera S.A.",
        "UBS in Buenos Aires": "UBS in Buenos Aires",
        "Unicredito Italiano": "Unicredito Italiano",
        "Volkswagen Argentina": "Volkswagen Argentina",
        "ABN AMRO Asset Management": "ABN AMRO Asset Management",
        "ABN AMRO Bank": "ABN AMRO Bank",
        "American Express Bank Ltd S.A.": "American Express Bank Ltd S.A.",
        "Banco B.i Creditanstalt S.A.": "Banco B.i Creditanstalt S.A.",
        "Banco Bisel": "Banco Bisel",
        "Banco Bradesco Argentina S.A.": "Banco Bradesco Argentina S.A.",
        "Banco Central de la República Argentina": "Banco Central de la República Argentina",
        "Banco Cetelem Argentina S.A.": "Banco Cetelem Argentina S.A.",
        "Banco Ciudad de Buenos Aires": "Banco Ciudad de Buenos Aires",
        "Banco Cmf S.A.": "Banco Cmf S.A.",
        "Banco Cofidis S.A.": "Banco Cofidis S.A.",
        "Banco Columbia": "Banco Columbia",
        "Banco Comafi": "Banco Comafi",
        "Banco Credicoop": "Banco Credicoop",
        "Banco Credicoop Cooperativo Limitado": "Banco Credicoop Cooperativo Limitado",
        "Banco de Balcarce S.A.": "Banco de Balcarce S.A.",
        "Otro": "Otro"
    }
}

export const codigosArea = {
    "Buenos Aires": "11",
    "Bahía Blanca": "291",
    "Mar Del Plata": "223",
    "La Plata": "221",
    "Córdoba": "351",
    "Corrientes": "379",
    "Formosa": "370",
    "La Rioja": "380",
    "Mendoza": "261",
    "Neuquén": "299",
    "Entre Ríos": "343",
    "Misiones": "376",
    "Chubut": "280",
    "Chaco": "362",
    "Río Gallegos": "2966",
    "Salta": "387",
    "Catamarca": "383",
    "San Juan": "264",
    "San Luis": "266",
    "Tucumán": "381",
    "Jujuy": "388",
    "Santa Fe": "342",
    "Santa Fe (Rosario)": "341",
    "La Pampa": "2954",
    "Santiago del Estero": "385",
    "Tierra del Fuego": "2901",
    "Río Negro": "2920",
}
