import styled from 'styled-components'
import MiddleBannerBgSvg from 'assets/home/middle-cta-bg-shape.svg'
import { ReactComponent as CalendarIconSVG } from 'assets/home/calendar-icon.svg'
import { ReactComponent as MiddleBannerPersonSVG } from 'assets/home/middle-cta-person.svg'
import { Grid } from '@material-ui/core'

export const MiddleBannerBg = styled.div`
  width: 100%;
  background-image: url(${MiddleBannerBgSvg});
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  bottom: 15vh;
  color: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    bottom: 13vh;
  }
`

export const MiddleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30vw;
  text-align: center;
  margin-top: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 100%;
    margin-top: 4rem;
    padding: 2rem 1rem 0 1rem;
  }
  & > p {
    height: 90px;
    max-height: 95px;
    margin-bottom: -2px;
  }
`
export const CalendarIcon = styled(CalendarIconSVG)`
  height: 50px;
  margin-bottom: 1rem;
  width: 100%;
`
export const MiddleBannerPerson = styled(MiddleBannerPersonSVG)`
  height: 100%;
  width: 49vw;
`

export const GridMiddleHero = styled(Grid)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0 auto;
    & > svg {
      height: 28vh;
    }
  }
`

export const GridContainer = styled(Grid)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 0;
    height: 100%;
  }
  height: 604px;
`

export const MiddleContainerCalendar = styled(Grid)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0 auto;
  }
`
