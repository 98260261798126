import React from 'react'
import { SummaryContainer, SummaryItem, SummaryItemText, LegendMessage, VerifyBall } from './styles'
import greenBall from 'assets/img/green-ball.svg'

const ProfileInfo = ({
  account: {
    id,
    first_name,
    last_name,
    email,
    dni,
    sex,
    salary,
    bank,
    is_validated_dni,
    is_validated_cbu,
    is_validated,
  },
}) => {
  return (
    <>
      <SummaryContainer>
        <LegendMessage> TUS DATOS SON: </LegendMessage>

        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            NOMBRE
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {first_name}
          </SummaryItemText>
        </SummaryItem>
        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            APELLIDO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {last_name}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            DNI
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {dni}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            SEXO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {sex}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            DNI VERIFICADO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {is_validated_dni ? <VerifyBall background={greenBall} /> : 'NO'}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            CORREO ELECTRÓNICO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {email}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            BANCO EMISOR DE LA CUENTA
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {bank}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            CBU VERIFICADO
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {is_validated_cbu ? <VerifyBall background={greenBall} /> : 'NO'}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText x variant="body1" gutterBottom>
            TODOS LOS DATOS VERIFICADOS
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {is_validated ? <VerifyBall background={greenBall} /> : 'NO'}
          </SummaryItemText>
        </SummaryItem>

        <SummaryItem>
          <SummaryItemText variant="body1" gutterBottom>
            ID EN EL SISTEMA
          </SummaryItemText>
          <SummaryItemText variant="body1" gutterBottom>
            {id}
          </SummaryItemText>
        </SummaryItem>
      </SummaryContainer>
    </>
  )
}

export default ProfileInfo
