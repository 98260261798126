import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const ResponsiveDialog = ({
  onClose,
  open,
  modalTitle,
  titleButtonAcept,
  titleButtonCancel,
  handleButtonAcept,
  handleButtonCancel,
  children,
  ...props
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose} {...props}>
        <DialogTitle id="responsive-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleButtonCancel} color="primary" autoFocus>
            {titleButtonCancel}
          </Button>
          <Button autoFocus onClick={handleButtonAcept} color="primary">
            {titleButtonAcept}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResponsiveDialog
