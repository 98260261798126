import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  h4: theme.typography.h4,
  h5: theme.typography.h5,

  root: {
    flexGrow: 1,
  },
  paperPrimary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    fontSize: '1.5rem',
  },
  paperSecondary: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    background: 'inherit',
    borderRadius: 0,
    border: 0,
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))

export const GeneralContent = styled.div`
  height: 100%;
  padding: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  ${({ fullWidth }) => fullWidth && 'background: #fff'}
  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
    `}
`

export const PictureContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  margin: 0 auto;
  button {
    width: 25%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 100%;
    width: 100%;
    button {
      width: 45%;
    }
  }
`
